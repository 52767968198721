import React, { Component, Fragment } from "react";
import instance from "./axios-instance";
import { css } from "@emotion/core";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/demo3.min.css'
import './css/tree.css'
import moment from 'moment';
import NumberFormat from 'react-number-format';
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cartlist: [],
            orderdetail: [],
            orderdetailpage: [],
            summaryprice: 0,
            orderno: "",
            sumpv: "",
            qrcode: "",
            stop: true,
            counts: 0
        }
    }



  
    componentDidMount() {
        var token = localStorage.getItem('auth_token');
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        var ordernos = params.get("orderno");
        instance.post("/checkorder_detail", {
            username: token,
            orderno: ordernos.toString()
        }).then((res) => {
            if (res.data.status === 200) {

                if (res.data.resdata !== null && res.data.resdata !== 'null') {
                    console.log(res.data);
                    this.setState({
                        data: 1,
                        orderdetail: res.data.resdata,
                        orderdetailpage: res.data.resdata[0],
                        qrcode: res.data.PictureQRcode,
                    });
                    this.summarylist(res.data.resdata);
                }
                else {
                  
                    window.location.href = '/home';
                }

            }
            else {

            }
        });
    }

    summarylist(cartlist) {
        var countsummary = 0;
        var sumpv = 0;
        cartlist.forEach(items => {
            countsummary = countsummary + (items.Quantity * items.UnitPrice);
            sumpv = sumpv + (items.PerPv * items.Quantity);
        });
        this.setState({
            summaryprice: countsummary,
            sumpv: sumpv,
        });
    }

    render() {
        const { OrderNo, OrderDate, PaymentStatus, TotalAmount, PayType, TotalPv } = this.state.orderdetailpage
        if (this.state.orderdetail.length > 0) {
            return (
                <React.Fragment>
                    <div>
                        <main className="main order">
                            <div className="page-content pt-8 pb-10 mb-10">
                                <div className="step-by pr-4 pl-4">
                                <h3 className="icon-box-title">คุณทำรายการจ่ายสำเร็จ</h3>
                                </div>
                                <div className="container mt-7">
                                    <div className="order-message">
                                        <div className="icon-box d-inline-flex align-items-center">
                                            <div className="icon-box-icon mb-0">

                                            </div>
                                            <h3 className="icon-box-title">ขอบคุณ. คุณทำการจ่ายผ่านคิวอาร์โค๊ดเสร็จสิ้น.</h3>
                                        </div>
                                    </div>
                                    <div className="order-results row cols-xl-6 cols-md-3 cols-sm-2 mt-10 pt-2 mb-4">
                                        <div className="overview-item">
                                            <span>เลขที่ออเดอร์</span>
                                            <label>{OrderNo}</label>
                                        </div>
                                        <div className="overview-item">
                                            <span>สถานะ</span>
                                            <label style={{ color: PaymentStatus != 1 ? "Red" : "Green" }}  >{PaymentStatus != 1 ? "รอการจ่ายเงิน" : "จ่ายเงินแล้ว"}</label>
                                        </div>
                                        <div className="overview-item">
                                            <span>วันที่</span>
                                            <label>{moment(OrderDate).format('YYYY-MM-DD HH:mm:ss')}</label>
                                        </div>
                                        <div className="overview-item">
                                            <span>การจ่าย:</span>
                                            <label>{PayType}</label>
                                        </div>
                                        <div className="overview-item">
                                            <span>ราคารวม:</span>
                                            <label >{<NumberFormat value={TotalAmount} displayType={'text'} thousandSeparator={true} />} บาท</label>
                                        </div>
                                        <div className="overview-item">
                                            <span>จ่ายผ่าน:</span>
                                            <label>QR Code</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return (
                <div className="dropdown cart-dropdown off-canvas">
                    <a href="#" className="cart-toggle link">
                        <i className="p-icon-cart-solid">
                        </i>
                        <span className="cart-label">
                            My Cart
                        </span>
                    </a>
                </div>);
        }
    }
}

export default App;
