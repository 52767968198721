import React, { Component, Fragment } from "react";
import logo from './logo.svg';
// import './App.css';
//import axios from 'axios';
import instance from "./axios-instance";
import Pagination from "react-js-pagination";
import Login from './login/Loginxx';
import { css } from "@emotion/core";
import jwt_decode from "jwt-decode";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/demo3.min.css'
import './css/tree.css'
import BinTree from './bintree';
import Orderlist from './order/orderlist';
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//
const liff = window.liff;
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            point: 0,
            adresseclect: null,
            cash: 0,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            profiledetail: null,
            name: '',
            address: null,
            pictureUrl: '',
            statusMessage: '',
            profile: '',
            languageDevice: '',
            versionSDK: '',
            client: '',
            isLogin: '',
            os: '',


        }
    }
    getProfile() {
        var token = localStorage.getItem('auth_token');
        liff.getProfile().then(dataInfo => {
            instance.post("/check_user", {
                type: "profile",
                username: token,
                userLineID: dataInfo.userId,
            }).then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        console.log(61, res.data.resdata);
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            point: res.data.resdata.WalletPoint,
                            cash: res.data.resdata.WalletCash,
                            profiledetail: res.data.resdata
                        });
                    }
                }
                else {
                }
            });
            this.setState({
                name: dataInfo.displayName,
                userLineID: dataInfo.userId,
                pictureUrl: dataInfo.pictureUrl,
                statusMessage: dataInfo.statusMessage
            });
        });


        var token = localStorage.getItem('auth_token');
        if (token) {
            // var decoded = jwt_decode(token);
            var Username = token;
            // this.rechecklogin(Username);
            this.setState({
                user_id: token,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }

        // liff.getProfile().then(dataInfo => {
        //     this.GetName(dataInfo.userId);
        //     this.setState({
        //         name: dataInfo.displayName,
        //         userLineID: dataInfo.userId,
        //         pictureUrl: dataInfo.pictureUrl,
        //         statusMessage: dataInfo.statusMessage
        //     });
        // });
        const languageDevice = liff.getLanguage();
        const versionSDK = liff.getVersion();
        const client = liff.isInClient();
        const isLogin = liff.isLoggedIn();
        const os = liff.getOS();

        this.setState({
            languageDevice: languageDevice,
            versionSDK: versionSDK,
            client: (client === true) ? 'YES' : 'NO',
            isLogin: (isLogin === true) ? 'Login' : 'Not Login',
            os: os
        });
    }
    closeLIFF() {
        liff.closeWindow();
    }

    componentDidMount() {
        const { userAgent } = navigator
        console.log(liff.isInClient());
        console.log(userAgent);
        if (userAgent.includes('Line')) {
            liff.init({ liffId: '1661498943-y6LEljeb' })
                .then(async () => {
                    if (!liff.isLoggedIn()) {
                        liff.login();
                    }
                    this.getProfile();
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
        else {
            var token = localStorage.getItem('auth_token');
            instance.post("/check_user", {
                username: token,
                type: "profile2",
                userLineID: undefined,
                userAgent: liff.isInClient() + "/" + userAgent,

            }).then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        //console.log(61, res.data.Address);
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            point: res.data.resdata.WalletPoint,
                            cash: res.data.resdata.WalletCash,
                            profiledetail: res.data.resdata,
                            address: res.data.Address,
                            adresseclect: res.data.Address[0],
                        });
                    }
                }
                else {
                }
            });

            var token = localStorage.getItem('auth_token');
            if (token) {
                // var decoded = jwt_decode(token);
                var Username = token;
                // this.rechecklogin(Username);
                this.setState({
                    user_id: token,
                    loginstate: true,
                });
            }
            else {
                this.setState({
                    loginstate: false,
                });
            }
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let { formData } = this.state;
        formData[name] = value;
        this.setState({
            formData: formData,
            hiddenfiled: "none",
        });
    }
    onclickssummit = (e) => {
        var token = localStorage.getItem('auth_token');
        instance.post("/confirmprofile_detail", {
            username: token,
            profile: this.state.formData
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {

                    }
                }
                else if (res.data.status === 201) {

                }
            });
    }
    validateLoginForm = (e) => {
        const { formData } = this.state;
        instance.post("/getuser", {
            username: formData.username,
            password: formData.password,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            point: res.data.resdata.WalletPoint,
                            cash: res.data.resdata.WalletCash,
                        });
                        window.location.reload();
                    }
                }
                else {
                }
            });
    }

    handleChange = (e) => {
        for (const element of this.state.address) {
            if (element.Seq == e.target.value) {
                this.setState({
                    adresseclect: element
                });

            }
        }
    }
    render() {

        if (this.state.profiledetail != null) {
            const { address, adresseclect } = this.state
            const { Name1, AddressNo, Mo, SubDistrict, District, Province, ZipCode, EMail } = this.state.profiledetail;
            return (

                <div className="">
                    <form action="#" style={{ fontSize: "16px", fontWeight: "bold" }}>
                        <div className="row">
                            <div className="col-sm-6 mb-4">
                                <label>ชื่อ</label>
                                <input type="text" name="Name1" onChange={this.handleInputChange} text={Name1 == null ? '' : Name1} placeholder="" required="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 mb-4">
                                <label>เลือกที่อยู่</label>
                                <select id="addressx" name="addressx" onChange={(e) => {
                                    this.handleChange(e)
                                }}>
                                    {address == null ? <option value="0">ไม่มีข้อมูล</option> : address.map(listitem => (
                                        <option value={listitem.Seq}>{listitem.AddressNo + " " + listitem.Province + " " + listitem.Postcode}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <label>ที่อยุ่สำหรับจัดส่ง*</label>
                            <div className="col-sm-6 mb-4">
                                <label>บ้านเลขที่</label>
                                <input type="text" name="AddressNo" value={adresseclect != null ? adresseclect.AddressNo : ""} placeholder="" required="" readOnly />
                            </div>
                            <div className="col-sm-6 mb-4">
                                <label>หมู่</label>
                                <input type="text" name="Mo"  value={adresseclect != null ? adresseclect.Mo : ""} placeholder="" required="" readOnly />
                            </div>
                            <div className="col-sm-6 mb-4">
                                <label>แขวง</label>
                                <input type="text" name="SubDistrict"  value={adresseclect != null ? adresseclect.SubDistrict : ""} placeholder="" required="" readOnly />
                            </div>
                            <div className="col-sm-6 mb-4">
                                <label>เขต</label>
                                <input type="text" name="District" value={adresseclect != null ? adresseclect.District : ""} placeholder="" required="" readOnly />
                            </div>
                            <div className="col-sm-6 mb-4">
                                <label>จังหวัด</label>
                                <input type="text" name="Province"  value={adresseclect != null ? adresseclect.Province : ""} placeholder="" required="" readOnly />
                            </div>
                            <div className="col-sm-3 mb-4">
                                <label>รหัสไปรณีย์</label>
                                <input type="text" name="ZipCode" value={adresseclect != null ? adresseclect.ZipCode : ""} placeholder="" required="" readOnly />
                            </div>
                        </div>
                        <label>Email Address *</label>
                        <input type="email" name="email" value={EMail == null ? '' : EMail} required="" />
                        <fieldset>
                            <label>Confirm password</label>
                            <input type="password" name="confirm_password" />
                        </fieldset>
                        <button type="submit" onClick={() => this.onclickssummit()} className="btn btn-primary">SAVE CHANGES</button>
                    </form>
                </div>

            );
        } else {
            return (<div className="">
                <form action="#">
                    <div className="row">
                        <div className="col-sm-6 mb-4">
                            <label>ชื่อ</label>
                            <input type="text" name="first_name" placeholder="" required="" />
                        </div>
                    </div>
                    <div className="row">
                        <label>ที่อยุ่สำหรับจัดส่ง*</label>
                        <div className="col-sm-6 mb-4">
                            <label>บ้านเลขที่</label>
                            <input type="text" name="first_name" required="" />
                        </div>
                        <div className="col-sm-6 mb-4">
                            <label>หมู่</label>
                            <input type="text" name="last_name" placeholder="" required="" />
                        </div>
                        <div className="col-sm-6 mb-4">
                            <label>แขวง</label>
                            <input type="text" name="last_name" placeholder="" required="" />
                        </div>
                        <div className="col-sm-6 mb-4">
                            <label>เขต</label>
                            <input type="text" name="last_name" placeholder="" required="" />
                        </div>
                        <div className="col-sm-6 mb-4">
                            <label>จังหวัด</label>
                            <input type="text" name="last_name" placeholder="" required="" />
                        </div>
                        <div className="col-sm-3 mb-4">
                            <label>รหัสไปรณีย์</label>
                            <input type="text" name="last_name" placeholder="" required="" />
                        </div>
                    </div>

                    <label>Email Address *</label>
                    <input type="email" name="email" required="" />
                    <fieldset>
                        <label>Confirm password</label>
                        <input type="password" name="confirm_password" />
                    </fieldset>
                    <button type="submit" className="btn btn-primary">SAVE CHANGES</button>
                </form>
            </div>);
        }
    }
}

export default App;
