import React, { Component, Fragment } from "react";
import logo from './logo.svg';
// import './App.css';
//import axios from 'axios';
import instance from "./axios-instance";
import Pagination from "react-js-pagination";
import Login from './login/Loginxx';
import { css } from "@emotion/core";
import jwt_decode from "jwt-decode";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/demo3.min.css'
import './css/tree.css'
import SweetAlert from 'react-bootstrap-sweetalert';
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            point: 0,
            cash: 0,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            profiledetail: null,
            memberrefdetail: null,
            membercodes: null,
            alert: null,
            pointinput: 0
        }
        this.hideAlert = this.hideAlert.bind(this);
    }

    async componentDidMount() {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        var membercodes = params.get("queid");
        var token = localStorage.getItem('auth_token');
        await instance.post("/check_user", {
            username: token
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.resdata !== null) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        point: res.data.resdata.WalletPoint,
                        cash: res.data.resdata.WalletCash,
                        profiledetail: res.data.resdata,
                        membercodes: membercodes
                    });
                }
            }
            else {
            }
        });

        await instance.post("/checkmember_coderefpoint", {
            username: token,
            membercodes: membercodes
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.resdata !== null) {                 
                    this.setState({
                        memberrefdetail: res.data.resdata,
                    });
                }
            }
            else {
              //  window.location.href = "/home";
            }
        });

        var token = localStorage.getItem('auth_token');
        if (token) {
            var Username = token;
            this.setState({
                user_id: token,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }
    hideAlertsuccess() {
    window.location.reload();
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }
    CheckThisGoalError(object) {
        const getAlert = () => (
            <SweetAlert
                error
                title={object.title}
                onConfirm={() => this.hideAlert()}  >
                {object.text}
            </SweetAlert>
        );

        this.setState({
            alert: getAlert()
        });
    }
    CheckThisGoalSuccess(object) {
        const getAlert = () => (
            <SweetAlert
                success
                title={object.title}
                onConfirm={() => this.hideAlertsuccess()}  >
                {object.text}
            </SweetAlert>
        );

        this.setState({
            alert: getAlert()
        });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let { formData } = this.state;
        formData[name] = value;
        this.setState({
            formData: formData,
        });
    }
    confirmpoint = (itemslist) => {
        const { formData } = this.state;
        var test =this.state.memberrefdetail;
        var sumpoint =  parseInt(test.WalletPoint) + parseInt(formData.pointinput)
        console.log(sumpoint);
        if (sumpoint <= 300) {
            if (formData.pointinput !== "" && formData.pointinput !== null && formData.pointinput !== undefined) {

                var token = localStorage.getItem('auth_token');
                instance.post("/confirm_uppoint", {
                    username: token,
                    membercodes: this.state.membercodes,
                    pointupdate: formData.pointinput,
                }).then((res) => {
                    if (res.data.status === 200) {
                        if (res.data.resdata !== null) {
                            var title = 'ทำรายการเรียบร้อย !'
                            var text = 'ทำรายการเพิ่มคะแนนเรียบร้อย'
                            var object = { title, text }
                            this.CheckThisGoalSuccess(object);                       
                        }
                    }
                    else if (res.data.status === 201) {
                        var title = 'ไม่สามารถทำรายการได้!'
                        var text = 'คุณใส่คะแนนเกินที่มีได้โปรดทำรายการใหม่อีกครั้ง'
                        var object = { title, text }
                        this.CheckThisGoalError(object);
                    }
                    else if (res.data.status === 202) {
                        var title = 'ไม่สามารถทำรายการได้!'
                        var text = 'คุณไม่ไช่เจ้าของรหัสสมาชิกนี้'
                        var object = { title, text }
                        this.CheckThisGoalError(object);
                    }
                    else if (res.data.status === 203) {
                        var title = 'ไม่สามารถทำรายการได้!'
                        var text = 'รหัสนี้เป็นรหัสบ้านไม่สามารถไส่เกิน 50 คะแนนได้'
                        var object = { title, text }
                        this.CheckThisGoalError(object);
                    }
                });
            }
            else {
                var title = 'ไม่สามารถทำรายการได้!'
                var text = 'คุณไม่ได้ไส่คะแนน'
                var object = { title, text }
                this.CheckThisGoalError(object);
            }
        }
        else {
            var title = 'ไม่สามารถทำรายการได้!'
            var text = 'คุณใส่คะแนนเกิน *เงื่อนใข ไส่ได้ไม่เกิด 300 ต่อ 1 รหัสมาชิก'
            var object = { title, text }
            this.CheckThisGoalError(object);
        }
    }
    render() {

        if (this.state.profiledetail != null) {
            const {  WalletPoint } = this.state.profiledetail;
            var memberref = this.state.memberrefdetail;
            return (<div className="main account-page">
                <nav className="breadcrumb-nav has-border">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li><a href="/main">หน้าหลัก</a></li>
                            <li>จัดการคะแนน</li>
                     
                        </ul>
                    </div>
                </nav>
                <div className="page-content">
                    <div className="container ">
                        <aside className="col-lg-8 sticky-sidebar-wrapper  pl-lg-6">
                            <div className="sticky-sidebar" data-sticky-options="{'bottom': 50}">
                                <div className="summary pt-5">
                                    <h3 className="title">จัดการคะแนน</h3>
                                    <h5 className="title">รหัสนี้เป็น {memberref !== null ? (memberref.Flag == "S" ? "รหัสสามัญ":"รหัสบ้าน"):""} </h5>
                                    <table className="order-sidebar">
                                        <thead>
                                        </thead>
                                        <tbody>
                                            <tr className="summary-total">
                                                <td>
                                                    <h4 className="summary-subtitle">คะแนนที่มีอยู่</h4>
                                                </td>
                                                <td>
                                                    <p className="summary-total-price ls-s" style={{ color: "#FF6347" }}>{WalletPoint} คะแนน</p>
                                                </td>
                                            </tr>
                                            <tr className="summary-total">
                                                <td>
                                                    <h4 className="summary-subtitle">คะแนนของรหัสนี้</h4>
                                                </td>
                                                <td>
                                                    <p className="summary-total-price ls-s" style={{ color: "#6bad0d" }}>{memberref !== null ? memberref.WalletPoint : 0} คะแนน</p>
                                                </td>
                                            </tr>
                                            <tr className="summary-total">
                                                <td >
                                                    <h4 className="summary-subtitle pb-3">รหัสสมาชิก</h4>
                                                </td>
                                                <td>
                                                    <p className="summary-total-price ls-s" style={{ color: "#FF6347" }}>{this.state.membercodes}</p>
                                                </td>
                                            </tr>
                                            <tr className="sumnary-shipping shipping-row-last">
                                                <td >
                                                    <h4 className="summary-subtitle pb-3">คะแนนที่ใส่</h4>
                                                </td>
                                                <td>
                                                    <input type="number" name="pointinput" onChange={this.handleInputChange} placeholder="" required="" />
                                                </td>
                                            </tr>
                                            <tr className="order-last"></tr>

                                            <tr className="order-last"></tr>
                                        </tbody>
                                    </table>
                                    <div className="payment accordion radio-type pb-5">

                                        <div id="collapse1" className="expanded" >
                                        <div className="card-body">
                                            หมายเหตุ* <br></br>
                                                รหัสบ้านจะใส่คะแนนสุงสุดได้ 50 คะแนน <br></br>
                                                รหัสสามัญใส่ได้ 300 คะแนน
                                            </div>
                                            <div className="card-body">
                                                คะแนนส่วนนี้จะไม่สามารถแก้ไขได้โปรดตรวจสอบก่อนการยืนยัน
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <a type="submit" onClick={this.confirmpoint} className="btn btn-dim btn-block mt-6 cartcheckout">ยืนยันการจัดการคะแนน</a>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
                {this.state.alert}
            </div>
            );
        } else {
            return (
                <div className="main account-page">
                    <nav className="breadcrumb-nav has-border">
                        <div className="container">
                            <ul className="breadcrumb">
                                <li><a href="/main">Home</a></li>
                                <li>ข้อมูลส่วนตัว</li>
                            </ul>
                        </div>
                    </nav>
                </div>);
        }
    }
}

export default App;
