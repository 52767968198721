import React, { Component, Fragment } from "react";
import instance from "./axios-instance";
import { css } from "@emotion/core";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/demo3.min.css'
import './css/tree.css'
import './index.css'
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import NumberFormat from 'react-number-format';
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cartlist: [],
            orderdetail: [],
            orderdetailpage: [],
            summaryprice: 0,
            orderno: "",
            sumpv: "",
            qrcode: "",
            time: 180000,
            stop: true,
            alert: null, // Indicates submit status of login form
            counts: 0
        }
        this.countdown = this.countdown.bind(this)
    }
    CheckThisGoalError(object) {
        const getAlert = () => (
            <SweetAlert
                error
                title={object.title}
                onConfirm={() => this.hideAlert()}  >
                {object.text}
            </SweetAlert>
        );

        this.setState({
            alert: getAlert()
        });
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }

    countdown(expiredDate) {
        const { countdown } = this.state
        const currentDate = new Date().getTime()
        let newTime = expiredDate - currentDate
        let start = true

        var date1 = new Date(currentDate);
        var date2 = new Date(expiredDate);
        var diff = date2.getTime() - date1.getTime();
        var seconds = Math.floor(diff / (1000));






        if (newTime < 0 || seconds < 25200) {
            newTime = this.state.time
            start = false
            clearInterval(countdown)
        }

        this.setState({
            counts: this.state.counts + 1,
        });

        //console.log(this.state.counts%10);
        if (this.state.counts % 10 == 0) {
            this.recheck();
        }

        this.setState({ time: newTime, start })
    }

    recheck() {
        var token = localStorage.getItem('auth_token');
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        var ordernos = params.get("orderno");
        instance.post("/checkorder_detail", {
            username: token,
            orderno: ordernos.toString()
        }).then((res) => {
            if (res.data.status === 200) {

                if (res.data.resdata !== null && res.data.resdata !== 'null') {
                    // console.log(res.data);    
                    // this.setState({
                    //     data: 1,
                    //     orderdetail: res.data.resdata,
                    //     orderdetailpage: res.data.resdata[0],
                    //     qrcode: res.data.PictureQRcode,
                    // });              
                    if (res.data.resdata[0].PaymentStatus == 1) {
                        // this.setState({ stop: false })     
                        window.location.href = '/OrderSuccess?orderno=' + res.data.resdata[0].OrderNo;;
                        // window.location.reload(false); 
                    }
                }
                else {
                    var title = 'ไม่สามารถทำรายการได้!'
                    var text = 'โปรดติดต่อเจ้าหน้าที่'
                    var object = { title, text }
                    this.CheckThisGoalError(object);
                }

            }
            else {

            }
        });
    }
    componentDidMount() {
        var token = localStorage.getItem('auth_token');
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        var ordernos = params.get("orderno");
        instance.post("/checkorder_detail", {
            username: token,
            orderno: ordernos.toString()
        }).then((res) => {
            if (res.data.status === 200) {

                if (res.data.resdata !== null && res.data.resdata !== 'null') {
                    console.log(res.data);

                    this.setState({
                        data: 1,
                        orderdetail: res.data.resdata,
                        orderdetailpage: res.data.resdata[0],
                        qrcode: res.data.PictureQRcode,

                    });

                    const { start, countdown, time } = this.state
                    if (!start) {
                        const expiredDate = new Date(res.data.QRTimeStamp).getTime() + time + 1000
                        const countdownFn = setInterval(() => this.countdown(expiredDate), 1000)
                        this.setState({ countdown: countdownFn })
                    } else {
                        clearInterval(countdown)
                        this.setState({ start: false })
                    }
                    this.summarylist(res.data.resdata);
                }
                else {

                    window.location.href = '/home';
                }

            }
            else {

            }
        });



    }

    confirmorder = (itemslist) => {
        var token = localStorage.getItem('auth_token');
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        var ordernos = params.get("orderno");
        instance.post("/renewqrcode", {
            username: token,
            orderno: ordernos.toString()
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.resdata !== null && res.data.resdata !== 'null') {
                    console.log(res.data);
                    this.setState({
                        data: 1,
                        orderdetail: res.data.resdata,
                        orderdetailpage: res.data.resdata[0],
                        qrcode: res.data.PictureQRcode,
                    });

                    const { start, countdown, time } = this.state
                    if (!start) {
                        const expiredDate = new Date(res.data.QRTimeStamp).getTime() + time + 1000
                        const countdownFn = setInterval(() => this.countdown(expiredDate), 1000)
                        this.setState({ countdown: countdownFn })
                    } else {
                        clearInterval(countdown)
                        this.setState({ start: false })
                    }
                    this.summarylist(res.data.resdata);
                }
                else {

                    window.location.href = '/home';
                }
            }
            else {
                var title = 'ไม่สามารถทำรายการได้!'
                var text = 'คุณพยายามสร้าง QRcode หลายครั้งไม่สามารถทำได้ โปรดติดต่อเจ้าหน้าที่'
                var object = { title, text }
                this.CheckThisGoalError(object);

            }
        });
    }
    summarylist(cartlist) {
        var countsummary = 0;
        var sumpv = 0;
        cartlist.forEach(items => {
            countsummary = countsummary + (items.Quantity * items.UnitPrice);
            sumpv = sumpv + (items.PerPv * items.Quantity);
        });
        this.setState({
            summaryprice: countsummary,
            sumpv: sumpv,
        });
    }

    render() {
        const { time, start } = this.state
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0')
        const seconds = Math.floor((time % (1000 * 60)) / 1000).toString().padStart(2, '0')
        const { OrderNo, OrderDate, PaymentStatus, TotalAmount, PayType, TotalPv } = this.state.orderdetailpage

        return (
            <React.Fragment>
                <div>
                    <main className="main order">
                        <div className="page-content pt-8 pb-10 mb-10">
                            <div className="step-by pr-4 pl-4">
                                <h3 className="title title-step"><a href="/cart">1.ตะกร้าสินค้า</a></h3>
                                <h3 className="title title-step"><a href="/checkout">2.สรุปการสั่งซื้อ</a></h3>
                                <h3 className="title title-step active"><a href="#">2.สั่งซื้อสำเร็จ</a></h3>
                            </div>
                            <div className="container mt-7">
                                <div className="order-message">
                                    <div className="icon-box d-inline-flex align-items-center">
                                        <div className="icon-box-icon mb-0">
                                        </div>
                                        <h3 className="icon-box-title">ขอบคุณ. เราได้รับออเดอร์นี้แล้วโปรดทำการสแกนจ่ายด้านล่างเพื่อให้ออเดอร์เสร็จสิ้น.</h3>
                                    </div>
                                </div>
                                <div style={{ display: (this.state.orderdetail.length === 0) ? "" : "none" }} className="progress">
                                    <div className="color"></div>
                                </div>
                                <div style={{ display: (this.state.orderdetail.length > 0) ? "" : "none" }} >
                                    <div className="order-results row cols-xl-6 cols-md-3 cols-sm-2 mt-10 pt-2 mb-4">
                                        <div className="overview-item">
                                            <span>เลขที่ออเดอร์</span>
                                            <label>{OrderNo}</label>
                                        </div>
                                        <div className="overview-item">
                                            <span>สถานะ</span>
                                            <label style={{ color: PaymentStatus != 1 ? "Red" : "Green" }}  >{PaymentStatus != 1 ? "รอการจ่ายเงิน" : "จ่ายเงินแล้ว"}</label>
                                        </div>
                                        <div className="overview-item">
                                            <span>วันที่</span>
                                            <label>{moment(OrderDate).format('YYYY-MM-DD HH:mm:ss')}</label>
                                        </div>
                                        <div className="overview-item">
                                            <span>การจ่าย:</span>
                                            <label>{PayType}</label>
                                        </div>
                                        <div className="overview-item">
                                            <span>ราคารวม:</span>
                                            <label >{<NumberFormat value={TotalAmount} displayType={'text'} thousandSeparator={true} />} บาท</label>
                                        </div>
                                        <div className="overview-item">
                                            <span>จ่ายผ่าน:</span>
                                            <label>QR Code</label>
                                        </div>
                                    </div>
                                    <h2 className="detail-title mb-6">รายละเอียดการสั่งซื้อ</h2>
                                    <div className="order-details">
                                        <table className="order-details-table">
                                            <thead>
                                                <tr className="summary-subtotal">
                                                    <td>
                                                        <h3 className="summary-subtitle">การสั่งซื้อของคุณ</h3>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr style={{ display: PaymentStatus != 1 ? "" : "none" }}>
                                                    <td className="summary-total-price">การจ่ายเงิน</td>
                                                    <td> <p className="summary-total-price" style={{ color: "black", paddingRight: "10px" }}> คิวอาร์โค๊ดหมดอายุ</p>
                                                        <p className="summary-total-price" style={{ color: "red", paddingRight: "50px" }}> {start == true ? `${minutes}:${seconds}` : "Qr Expire"} </p>
                                                        <a type="submit" style={{ display: start !== true ? "" : "none" }} onClick={this.confirmorder} className="btn btn-dim mt-2 cartcheckout">ขอ QR Code ใหม่</a></td>
                                                    <td><img src={this.state.qrcode} alt="product" width="40" height="60" />

                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td className="product-subtitle">สินค้า</td>
                                                    <td>คะแนน</td>
                                                    <td>ราคา</td>
                                                </tr>
                                                <tr className="summary-subtotal">
                                                    <td>
                                                        <h4 className="summary-subtitle">จ่ายผ่าน:</h4>
                                                    </td>
                                                    <td className="summary-value" style={{ display: PaymentStatus != 1 ? "" : "none" }} >QR Code</td>
                                                </tr>
                                                {this.state.orderdetail.map(listitem => {
                                                    return listitem.ItemNo > 0 ?
                                                        <React.Fragment key={listitem.ItemNo}>
                                                            <tr key={listitem.ItemNo}>
                                                                <td className="product-name">{listitem.ProductDesc} <span><i className="p-icon-times"></i> {listitem.Quantity}</span>
                                                                </td>
                                                                <td className="product-pv">{listitem.PerPv * listitem.Quantity } คะแนน</td>
                                                                <td className="product-price">{<NumberFormat value={listitem.UnitPrice * listitem.Quantity} displayType={'text'} thousandSeparator={true} />} บาท</td>

                                                            </tr>
                                                        </React.Fragment> : <tr key="0"></tr>
                                                })}
                                                <tr className="summary-subtotal"></tr>
                                                <tr className="summary-subtotal">
                                                    <td>
                                                        <h4 className="summary-subtitle">ยอดรวม:</h4>
                                                    </td>
                                                    <td></td>
                                                    <td className="summary-value font-weight-normal">{<NumberFormat value={TotalAmount} displayType={'text'} thousandSeparator={true} />} บาท</td>
                                                </tr>
                                                <tr className="summary-subtotal">
                                                    <td>
                                                        <h4 className="summary-subtitle">ยอดสุทธิ:</h4>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <p className="summary-total-price" style={{ color: "#6bad0d" }}>{<NumberFormat value={TotalAmount} displayType={'text'} thousandSeparator={true} />} บาท</p>
                                                    </td>
                                                </tr>
                                                <tr className="summary-subtotal">
                                                    <td>
                                                        <h4 className="summary-subtitle">รวมคะแนน :</h4>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <p className="summary-total-price" style={{ color: "#FF6347" }}>{TotalPv } คะแนน</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    {this.state.alert}
                </div>
            </React.Fragment>
        );

    }
}

export default App;
