import React, { Component } from 'react';
import logo from './logo.svg';
// import './App.css';
import axios from 'axios';
import Pagination from "react-js-pagination";
import Login from './login/Loginxx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { css } from "@emotion/core";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import MyLoader from './Loading'
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import system from "./objectdata";
var Productionlink =system.REACT_APP_API_LINKPIC 
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            productInter: null,
            productNew: null,
            cash: 0,
            profiledetail: null,
            search: null,
            productlist: null,
            productlistpicture: [],
            cartlist: [],
            values: 1,
            productlistdesc: null,
            configlink: null
        }
    }

    componentDidMount() {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        var search = params.get("code");
        var token = localStorage.getItem('auth_token');
        this.setState({
            search: search,
        });
        instance.post("/product_detail", {
            productcode: search,
            username: token
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.resdata_newproduct !== null) {
                    localStorage.setItem('auth_token', res.data.token)
                    console.log(  res.data.resdata_productdesc[0],57);
                    this.setState({
                        
                        productlist: res.data.resdata,
                        productlistpicture: res.data.resdata_newproduct,
                        productlistdesc: res.data.resdata_productdesc[0],
                        configlink: res.data.configlink
                    });
                    console.log(res.data.resdata_newproduct);
                    console.log(res.data.configlink);
                }
            }
            else {
            }
        });

        var cart_list = localStorage.getItem('cart_list');
        if (cart_list != 'null') {
            this.setState({
                cartlist: cart_list,
            });
        }
        var token = localStorage.getItem('auth_token');
        if (token) {
            // var decoded = jwt_decode(token);
            var Username = token;
            // this.rechecklogin(Username);
            this.setState({
                user_id: token,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    plus = () => {
        // var cartlist = this.state.cartlist
        // cartlist.forEach(items => {
        //     if (items.id == this.state.search) {
        //         items.productqty = items.productqty + 1
        //     }
        // });
        this.setState({
          //  cartlist: cartlist,
            values: this.state.values + 1,
        });
       // this.summarylist(cartlist);
      //  localStorage.setItem('cart_list', JSON.stringify(cartlist))
       // window.location.reload(false);
    }
    minus = () => {

        // var cartlist = this.state.cartlist
        // cartlist.forEach(items => {
        //     if (items.id == this.state.search) {
        //         items.productqty = items.productqty - 1
        //     }
        // });
        this.setState({
           // cartlist: cartlist,
            values: this.state.values - 1,
        });
      //  this.summarylist(cartlist);
        //localStorage.setItem('cart_list', JSON.stringify(cartlist))
       // window.location.reload(false);
    }
    addcart = (product) => {
        var cart_list = localStorage.getItem('cart_list');
        var carttlistobj = []
        if (cart_list != null) var carttlistobj = JSON.parse(cart_list);
        var price = product.ProductPrice;
        var productCode = product.ProductCode;
        var productqty = this.state.values;
        var productName = product.ProductDesc;
        var id = carttlistobj.length + 1
        var PV = product.ProductPv
        var Image = product.Path
        var cartlists = { price, productCode, productqty, productName, id, PV, Image }
        var checked = false;
        carttlistobj.forEach(items => {
            if (items.productCode == product.ProductCode) {
                items.productqty += productqty
                checked = true;
            }
        });

        if (!checked) carttlistobj.push(cartlists);
        this.setState({
            cartlist: carttlistobj,
        });
        localStorage.setItem('cart_list', JSON.stringify(carttlistobj))
        window.location.reload(false);
    }
    render() {
        var Product = this.state.productlist;
        var Config = this.state.configlink;
        const { values } = this.state;
        if (Product !== null) {
            return (
                <div>
                    <main className="main single-product">
                        <nav className="breadcrumb-nav">
                            <div className="container">
                                <div className="product-navigation">
                                    <ul className="breadcrumb">
                                        <li><a href="/main">หน้าหลัก</a></li>
                                        <li><a href="#">สินค้า</a></li>
                                    </ul>
                                    <div className="product-image-nav">
                                        <a href="#" className="product-nav product-nav-prev">
                                            <figure>
                                                <img src="images/products/product-single/navigation/prev.jpg" width="150"
                                                    height="150" alt="nav-prev" />
                                            </figure>
                                            <i className="p-icon-arrow-long-left"></i>
                                        </a>
                                        <a href="#" className="product-nav product-nav-next">
                                            <figure>
                                                <img src="images/products/product-single/navigation/next.jpg" width="150"
                                                    height="150" alt="nav-next" />
                                            </figure>
                                            <i className="p-icon-arrow-long-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="page-content">
                            <div className="container">
                                <div className="product product-single product-simple row mb-8">
                                    <div className="col-md-7">
                                        <div className="product-gallery">
                                            {this.state.productlistpicture != null ? this.state.productlistpicture.map(listitem => (
                                                <div key={listitem.ProductCode} className="product-single-carousel owl-carousel owl-theme owl-nav-inner row cols-1 gutter-no">
                                                    <figure className="product-image">
                                                        <img src={listitem.Path !== null ? (Config + "/" + listitem.Path) : Productionlink + "/Files/Images/default-image.jpg"} alt="1" width="800" height="1000" />
                                                    </figure>
                                                </div>
                                            )) : ""}
                                            <div className="product-thumbs-wrap">
                                                {this.state.productlistpicture != null ? this.state.productlistpicture.map(listitem => (
                                                    <div key={listitem.ProductCode} className="product-thumbs">
                                                        <div className="product-thumb">
                                                            <img src={listitem.Path !== null ? (Config + "/" + listitem.Path) : Productionlink + "/Files/Images/default-image.jpg"} alt="1" style={{ width: "150px" }} />
                                                        </div>
                                                    </div>
                                                )) : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="product-details">
                                            <h1 className="product-name">{this.state.productlist[0].ProductDesc}</h1>
                                            <p className="product-price mb-1">
                                                <ins className="new-price">{this.state.productlist[0].ProductPrice} บาท</ins>
                                            </p>
                                            <p className="product-pv mb-1">
                                                <ins className="new-pv">{this.state.productlist[0].ProductPv} คะแนน</ins>
                                            </p>
                                            <p className="product-short-desc">{this.state.productlist[0].ProductDetail}

                                            </p>
                                            <div className="product-variation-price">
                                                <span>{this.state.productlist[0].ProductPrice}</span>
                                            </div>
                                            <div className="product-form product-qty pt-1">
                                                <div className="product-form-group">
                                                    <div className="input-group">
                                                        <button className="quantity-minus p-icon-minus-solid" onClick={() => this.minus()}></button>
                                                        <input className="quantity form-control" type="number" value={values == null ? '1' : values} tex min="1" max="100" />
                                                        <button className="quantity-plus p-icon-plus-solid" onClick={() => this.plus()}    ></button>
                                                    </div>
                                                    <button className="btn-product btn-cart ls-normal font-weight-semi-bold" onClick={() => this.addcart(this.state.productlist[0])}  ><i
                                                        className="p-icon-cart-solid"></i>ADD TO CART</button>
                                                </div>
                                            </div>
                                            <div className="product-action pt-5 pb-3">
                                                <a href="#" className="btn-product btn-wishlist"><i className="p-icon-heart-solid"></i>ADD
                                                    TO
                                                    WISHLIST</a>
                                            </div>
                                            <hr className="product-divider" />
                                            <div className="product-meta">
                                                <label>CATEGORIES:</label><a href="#">fruit</a> , <a href="#">daily needs</a><br />
                                                <label>sku:</label><a href="#">mS46891357</a><br />
                                                <label>tag:</label><a href="#">organic</a> , <a href="#">greenhouse</a> , <a
                                                    href="#">fat</a> , <a href="#">healthy</a> , <a href="#">dairy</a> ,
                                                <a href="#">vitamin</a><br />
                                                <label className="social-label">share:</label>
                                                <div className="social-links">
                                                    <a href="#" className="social-link fab fa-facebook-f"></a>
                                                    <a href="#" className="social-link fab fa-twitter"></a>
                                                    <a href="#" className="social-link fab fa-pinterest"></a>
                                                    <a href="#" className="social-link fab fa-linkedin-in"></a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <div className="content-description">
                                        <h2 className="title title-line title-underline mb-lg-8">
                                            <span>
                                                คำอธิบาย
                                            </span>
                                        </h2>
                                        <h3 className="content-title">
                                            เกี่ยวกับผลิตภัณฑ์นี้
                                        </h3>
                                        <section
                                        dangerouslySetInnerHTML={{ __html:this.state.productlistdesc.Description }}
                                    />
                                    </div>
                                    {/* <div className="content-specification mt-10 pt-3">
                                        <h2 className="title title-line title-underline">
                                            <span>
                                                Specifications
                                            </span>
                                        </h2>
                                        <ul className="list-none">
                                            <li><label>WEIGHT</label>
                                                <p>5 kg</p>
                                            </li>
                                            <li><label>DIMENSIONS</label>
                                                <p>10 × 10 × 10 cm</p>
                                            </li>
                                            <li><label>WEIGHT UNIT</label>
                                                <p>1KG, 1LB, 500G, Bound, Each</p>
                                            </li>
                                        </ul>
                                    </div> */}

                                </div>
                            </div>
                        </div>

                    </main >
                </div >
            );
        }
        else {
            return (<div></div>
            );
        }


    }
}

export default App;
