import React, { Component } from 'react';
import logo from './logo.svg';
// import './App.css';
import axios from 'axios';
import Pagination from "react-js-pagination";
import Login from './login/Loginxx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { css } from "@emotion/core";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import MyLoader from './Loading'
import jwt_decode from "jwt-decode";
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//

class App extends Component {

    render() {
        return (
            <div className="mobile-menu-container scrollable">
                <div className="tab tab-nav-underline tab-nav-boxed">
                    <ul className="nav nav-tabs nav-fill mt-4 mb-4">
                        <li className="nav-item">
                            <a className="nav-link active" href="#mm-tab-1">หน้าหลัก</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#mm-tab-2">เมนูสินค้า</a>
                        </li>
                    </ul>
                    <div className="mobile-menu mmenu-anim tab-content">
                        <div className="tab-pane active in" id="mm-tab-1">
                            <ul>
                                <li>
                                    <a href="/">หน้าหลัก</a>
                                </li>
                                <li>
                                    <a href="/">สินค้าขายดี</a>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-pane" id="mm-tab-2">
                            <ul>
                                <li>
                                    <a href="#"><i className="p-icon-fruit"></i>ทั่วไป</a>
                                    <ul>
                                        <li>
                                            <a href="/home?search=ชนิดสินค้า">ชนิดสินค้า</a>
                                            <ul>
                                                <li><a href="/home?search=ชนิดน้ำ">ชนิดน้ำ</a></li>
                                                <li><a href="/home?search=ชนิดแคปซูล">ชนิดแคปซูล</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="/home?search=สินค้าพิเศษ">สินค้าพิเศษ</a>
                                            <ul>
                                                <li><a href="/home?search=สินค้าพิเศษ">สินค้าพิเศษ</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default App;
