const data = {
    REACT_APP_API_HOST: 'https://uat.muyzen.co.th',
    REACT_APP_ANY_PORT: '3012',
    REACT_APP_API_LINK: 'https://uat.muyzen.co.th',
    REACT_APP_API_LINKPIC: 'https://beta.muyzen.co.th:12444',
    REACT_APP_API_QR_HOST: 'https://openapi-test.kasikornbank.com',
    REACT_APP_ANY_QR_PORT: '443',
    REACT_APP_ANY_QR_AUTH_TOKEN: '----',
    REACT_APP_ANY_QR_PARTNER: 'CRP0000472',
    REACT_APP_ANY_QR_CONSUMERID: 'ch9Cj8f5c7Cel9ODsC1e3P6GP7bvm6jg',
    REACT_APP_ANY_QR_CONSUMERSECRET: 'WP8xUADSzo7jhmkn',
    REACT_APP_ANY_QR_MAERCABTID: 'KB000000523220',
    REACT_SECRET: "Y2g5Q2o4ZjVjN0NlbDlPRHNDMWUzUDZHUDdidm02amc6V1A4eFVBRFN6bzdqaG1rbg::",
    REACT_APP_ANY_QR_PARTNERSECRET: 'zq469sr4pZ77Yspm0fuFT5mlZNW3CRxkvWzWMk4rPEZt2yxAm1vSBoXhAsiagRNb',
}

const datax = {
    REACT_APP_API_HOST: 'https://cs.muyzen.co.th',
    REACT_APP_ANY_PORT: '3012',
    REACT_APP_API_LINK: 'https://cs.muyzen.co.th',
    REACT_APP_API_LINKPIC: 'https://backoffice.muyzen.co.th',
    REACT_APP_API_QR_HOST: 'https://openapi.kasikornbank.com',
    REACT_APP_ANY_QR_AUTH_TOKEN: '----',
    REACT_APP_ANY_QR_PARTNER: 'CRP0000240',
    REACT_APP_ANY_QR_CONSUMERID: 'Souvwi7cN78bKpg7piryPTeVEHgYJDWG',
    REACT_APP_ANY_QR_CONSUMERSECRET: 'WIB6FgGXGGFYEXom',
    REACT_APP_ANY_QR_MAERCABTID: 'KB000000523220',
    REACT_SECRET: "Y2g5Q2o4ZjVjN0NlbDlPRHNDMWUzUDZHUDdidm02amc6V1A4eFVBRFN6bzdqaG1rbg==",
    REACT_APP_ANY_QR_PARTNERSECRET: 'StExtCyLRJVssKz5QaLFbe6kyXE837r3WZhuhoGX4wlvdaWZwX0aKXRKlkw4ZclO',
}
var api_URL;
if (process.env.NODE_ENV === "development") api_URL = data
else api_URL = datax
const system = datax;
export default system;