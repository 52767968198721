import React, { Component, Fragment } from "react";
import logo from './logo.svg';
// import './App.css';
//import axios from 'axios';
import instance from "./axios-instance";
import Pagination from "react-js-pagination";
import Login from './login/Loginxx';
import { css } from "@emotion/core";
import jwt_decode from "jwt-decode";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/tree.css'
import './css/style.min.css'
import './css/demo3.min.css'
import BinTree from './bintree';
import Profile from './profile';
import Lineoff from './lineoff';
import Orderlist from './order/orderlist';
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            treelist: [],
        }
    }
    validateLogout = (e) => {
        localStorage.removeItem('auth_token')
        localStorage.removeItem('cart_list')
    }
    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            window.location.href = "/home";
        } else {
            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.href = "/home";
        }
    }

    render() {
        return (
            <div className="main account-page">
                <nav className="breadcrumb-nav has-border">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li><a href="/main">หน้าหลัก</a></li>
                            <li>ข้อมูลส่วนตัว</li>
                        </ul>
                    </div>
                </nav>
                <div className="page-content mt-4 mb-10 pb-6">
                    <div className="container">
                        <div className="tab tab-vertical gutter-lg">
                            <ul className="nav nav-tabs mb-8 col-lg-3 col-md-4">
                                <li className="nav-item">
                                    <a className="nav-link active" href="#dashboard">เมนู</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#orders">รายการสั่งซื้อ</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#membertree">ลำดับขั้น</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#account">จัดการข้อมูลส่วนตัว</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#lineoff">ผูก Line Officail</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link no-tab-item" href="wishlist.html">สินค้าโปรด</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link no-tab-item" style={{ cursor: "pointer " }} onClick={this.logout} >ออกจากระบบ</a>
                                </li>
                            </ul>
                            <div className="tab-content col-lg-9 col-md-8">
                                <div className="tab-pane active" id="dashboard">
                                    <div className="row cols-lg-3 cols-xs-2 cols-1 nav">
                                        <div className="ib-wrapper mb-4">
                                            <div className="icon-box text-center ib-border"><a href="#orders">
                                                <span className="icon-box-icon">
                                                    <i className="p-icon-orders"></i>
                                                </span>
                                                <div className="icon-box-content">
                                                    <p>รายการสั่งซื้อ</p>
                                                </div>
                                            </a>
                                            </div>
                                        </div>
                                        <div className="ib-wrapper mb-4">
                                            <div className="icon-box text-center ib-border"><a href="#account">
                                                <span className="icon-box-icon">
                                                    <i className="p-icon-map"></i>
                                                </span>
                                                <div className="icon-box-content">
                                                    <p>จัดการที่จัดส่ง</p>
                                                </div>
                                            </a>
                                            </div>
                                        </div>
                                        <div className="ib-wrapper mb-4">
                                            <div className="icon-box text-center ib-border"><a href="#account">
                                                <span className="icon-box-icon">
                                                    <i className="p-icon-user-solid"></i>
                                                </span>
                                                <div className="icon-box-content">
                                                    <p>ข้อมูลส่วนตัว</p>
                                                </div>
                                            </a>
                                            </div>
                                        </div>
                                        <div className="ib-wrapper mb-4">
                                            <div className="icon-box text-center ib-border"><a href="wishlist.html"
                                                className="no-tab-item">
                                                <span className="icon-box-icon">
                                                    <i className="p-icon-heart-solid"></i>
                                                </span>
                                                <div className="icon-box-content">
                                                    <p>สินค้าโปรด</p>
                                                </div>
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="orders">
                                    <Orderlist />
                                </div>
                                <div className="tab-pane" id="membertree">
                                    <div className="tree">
                                        <BinTree />
                                    </div>
                                </div>
                                <div className="tab-pane" id="account">
                                    <Profile />
                                </div>
                                <div className="tab-pane" id="lineoff">
                                    <Lineoff />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  </div>
        );
    }
}

export default App;
