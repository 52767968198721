import React, { Component, Fragment } from "react";
import logo from './logo.svg';
// import './App.css';
//import axios from 'axios';
import instance from "./axios-instance";
import Pagination from "react-js-pagination";
import Login from './login/Loginxx';
import { css } from "@emotion/core";
import jwt_decode from "jwt-decode";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/demo3.min.css'
import './css/tree.css'
import BinTree from './bintree';
import Orderlist from './order/orderlist';
import QRCode from "react-qr-code";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//
const liff = window.liff;
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            point: 0,
            cash: 0,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            profiledetail: null,
            name: '',
            userLineID: null,
            pictureUrl: '',
            statusMessage: '',
            profile: '',
            languageDevice: '',
            versionSDK: '',
            client: '',
            isLogin: '',
            os: '',
            LinePasskey: '',



        }
    }

    closeLIFF() {
        liff.closeWindow();
    }

    componentDidMount() {
        const { userAgent } = navigator
        console.log(liff.isInClient());
        console.log(userAgent);
        if (userAgent.includes('Line')) {
            liff.init({ liffId: '1661498943-y6LEljeb' })
                .then(async () => {
                    if (!liff.isLoggedIn()) {
                        liff.login();
                    }
                    this.getProfile();
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
        else {
            var token = localStorage.getItem('auth_token');
            instance.post("/check_user", {
                username: token,
                type: "lineoff",
                userLineID: undefined,
                userAgent: liff.isInClient() + "/" + userAgent,

            }).then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        localStorage.setItem('auth_token', res.data.token)
                        console.log(res.data.resdata);
                        this.setState({
                            LinePasskey: (res.data.resdata.LinePasskey=== null)? "0":res.data.resdata.LinePasskey,
                            point: res.data.resdata.WalletPoint,
                            userLineID: res.data.resdata.userLineID,
                            name: res.data.resdata.Linename,
                            pictureUrl: res.data.resdata.LinepictureUrl,
                            cash: res.data.resdata.WalletCash,
                            profiledetail: res.data.resdata
                        });
                    }
                }
                else {
                }
            });

            var token = localStorage.getItem('auth_token');
            if (token) {
                // var decoded = jwt_decode(token);
                var Username = token;
                // this.rechecklogin(Username);
                this.setState({
                    user_id: token,
                    loginstate: true,
                });
            }
            else {
                this.setState({
                    loginstate: false,
                });
            }
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let { formData } = this.state;
        formData[name] = value;
        this.setState({
            formData: formData,
            hiddenfiled: "none",
        });
    }
    onclickssummit = (e) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        instance.post("/genlineofficial", {
            username: token
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.LinePasskey !== null) {
                        this.setState({
                            LinePasskey: res.data.LinePasskey,
                            userLineID: res.data.userLineID,
                            hiddenfiled: "none",
                        });
                    }
                }
                else if (res.data.status === 201) {

                }
            });
    }

    onclicksunlink = (e) => {
        var token = localStorage.getItem('auth_token');
        instance.post("/genlineunlink", {
            username: token
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.LinePasskey !== null) {
                        this.setState({
                            LinePasskey: res.data.LinePasskey,
                            userLineID: res.data.userLineID,
                            hiddenfiled: "none",
                        });
                    }
                }
                else if (res.data.status === 201) {

                }
            });
    }
    validateLoginForm = (e) => {

    }

    handleChange = (e) => {

    }
    render() {
        const { userLineID, name, pictureUrl } = this.state
        console.log(userLineID);
        if (userLineID === null || userLineID === '' || userLineID === undefined) {
            return (

                <div className="">
                    <form action="#">
                        <div className="container">
                            <div className="row">
                                <div className="icon-boxes mt-4 col-lg-6" style={{ color: "black" }} >
                                    <h4 >รหัสผูก QR code Line </h4>
                                    <img src="https://digitalmarketingwow.com/wp-content/uploads/bfi_thumb/1200x630-331m50v2a9cg9nv3d0zx9m40nykivkjg27sljih9cgmdiak2o.png" alt="banner"
                                        width="50" height="50" style={{ backgroundColor: " #cfd5c1" }} />
                                    <br></br>
                                    <div className="row" style={{ marginTop: "20px", textAlign: "center", display: (this.state.LinePasskey == "0") ? "none" : "" }}>
                                        <QRCode
                                            size={256}
                                            style={{ height: "50%", maxWidth: "100%", width: "100%" }}
                                            value={this.state.LinePasskey}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                    <div className="icon-box" style={{display: (this.state.LinePasskey == "0") ? "none" : "" }} >
                                        <label>PassKey(นำ Key ไปใสในตอนสมัคร หรือสแกน Qrcode ด้านบน)</label>
                                        <input type="text" style={{ color: "black", fontWeight: "800" }} value={this.state.LinePasskey} disabled name="Passkey" />
                                    </div >
                                    <button  style={{marginTop:"10px"}} type="submit" onClick={(e) => this.onclickssummit(e)} className="btn btn-primary">Genarate</button>
                                </div >
                                <div className="icon-boxes mt-8 col-lg-6">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            );
        }
        else {
            return (

                <div className="">
                    <form action="#">
                        <div className="container">
                            <div className="row">
                                <div className="icon-boxes mt-4 col-lg-6" >
                                    <h4 >รหัสผูก QR code Line </h4>
                                    <img src="https://digitalmarketingwow.com/wp-content/uploads/bfi_thumb/1200x630-331m50v2a9cg9nv3d0zx9m40nykivkjg27sljih9cgmdiak2o.png"
                                        width="128" height="128" style={{ backgroundColor: " #cfd5c1" }} />
                                    <div className="icon-box icon-box">
                                        <div className="icon-box-content">
                                            <h2>{name}</h2>
                                        </div>
                                        <span className="icon-box-icon">
                                            <img src={pictureUrl} style={{ backgroundColor: " #cfd5c1", width: 128, height: 128 }} />
                                        </span>

                                    </div>
                                    <button type="submit" onClick={() => this.onclicksunlink()} className="btn btn-danger">Unlink</button>
                                </div >
                                <div className="icon-boxes mt-8 col-lg-6">
                                    <div className="icon-box icon-box">
                                        <span className="icon-box-icon">
                                            <i className="p-icon-shipping-solid"></i>
                                        </span>
                                        <div className="icon-box-content">
                                            <h4 className="icon-box-title">แจ้งเตือน</h4>
                                            <p>รับแจ้งเตือนผ่านไลน์ กิจกรรม และคะแนน</p>
                                        </div>
                                    </div>
                                    <div className="icon-box icon-box">
                                        <span className="icon-box-icon">
                                            <i className="p-icon-quality"></i>
                                        </span>
                                        <div className="icon-box-content">
                                            <h4 className="icon-box-title">สั่งสินค้า</h4>
                                            <p>สามารถสั่งสินค้าผ่านทางไลน์ได้โดยไม่ต้อง Login</p>
                                        </div>
                                    </div>
                                    <div className="icon-box icon-box">
                                        <span className="icon-box-icon">
                                            <i className="p-icon-fax2"></i>
                                        </span>
                                        <div className="icon-box-content">
                                            <h4 className="icon-box-title">ติดตาม</h4>
                                            <p>สามารถติดตามสินค้าได้ผ่านทางไลน์รวดเร็ว !</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            );
        }

    }
}

export default App;
