import axios from 'axios';




// const instance = axios.create({
//   baseURL: process.env.REACT_APP_API_HOST + ":" + process.env.REACT_APP_ANY_PORT
// });

var api_URL= 'https://uat.muyzen.co.th:3013'
if (process.env.NODE_ENV === "development") {
  api_URL = 'https://uat.muyzen.co.th:3013'
}
else if (process.env.NODE_ENV === "production") {
  api_URL = 'https://cs.muyzen.co.th:3013'
}
const instance = axios.create({
  baseURL:  'https://uat.muyzen.co.th:3013'
});


console.log(process.env.NODE_ENV);
const AUTH_TOKEN = "123"
instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export default instance;

