import React, { Component, Fragment } from "react";
// import './App.css';
//import axios from 'axios';
import instance from "../axios-instance";
import { css } from "@emotion/core";
import jwt_decode from "jwt-decode";
import '../vendor/fontawesome-free/css/all.min.css'
import '../vendor/animate/animate.min.css'
import '../vendor/owl-carousel/owl.carousel.min.css'
import '../vendor//magnific-popup/magnific-popup.min.css'
import '../vendor//magnific-popup/magnific-popup.min.css'
import '../css/demo3.min.css'
import '../css/tree.css'
import NumberFormat from 'react-number-format';
import moment from 'moment';
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderlist: null,
            point: 0,
            cash: 0,
            list: [],
            listdata: [],
            data: 0,
            orderdetail: [],
            orderdetailpage: [],
            bliingdetail: null,
        }
    }
    async componentDidMount() {
        var token = localStorage.getItem('auth_token');
        await instance.post("/check_order", {
            username: token,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        this.setState({
                            data: 0,
                            orderlist: res.data.resdata,
                        });
                    }
                }
                else {
                }
            });

        var token = localStorage.getItem('auth_token');
        if (token) {
            // var decoded = jwt_decode(token);
            var Username = token;
            // this.rechecklogin(Username);
            this.setState({
                user_id: token,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }


    }


    onclicks = (orderno) => {
        var token = localStorage.getItem('auth_token');
        instance.post("/checkorder_detail", {
            username: token,
            orderno: orderno
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        // console.log(  res.data.resdata[0]);
                        this.setState({
                            data: 1,
                            orderdetail: res.data.resdata,
                            orderdetailpage: res.data.resdata[0],
                        });
                    }

                    if (res.data.resdata2 !== null) {
                        this.setState({
                            bliingdetail: res.data.resdata2[0]
                        });
                    }

                }
                else {
                }
            });
    }

    onclickscancel = (e) => {

        this.setState({
            data: 0,
            bliingdetail: null
        });

    }

    onclickspayagain = (e) => {
        window.location.href = '/orderfinish?orderno=' + this.state.orderdetailpage.OrderNo;

    }
    render() {
        if (this.state.orderlist !== null && this.state.data == 0) {
            return (
                <React.Fragment>
                    <div id="orders-view">
                        <table className="order-table">
                            <thead>
                                <tr>
                                    <th>รหัสออเดอร์</th>
                                    <th>วันที่สั่งซื้อ</th>
                                    <th>สถานะ</th>
                                    <th>ราคารวม</th>
                                    <th>ดูรายการ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.orderlist.map(listitem => (

                                    <tr key={listitem.OrderNo}>
                                        <td className="order-number"><a href="#">#{listitem.OrderNo}</a></td>
                                        <td className="order-date"><span>{moment(listitem.DatePost).format('YYYY-MM-DD HH:mm:ss')}</span></td>
                                        <td className="order-total">{(listitem.PaymentStatus !== 0 ? <span style={{ color: "green" }}>จ่ายแล้ว</span> : <span style={{ color: "red" }}>ยังไม่จ่าย</span>)}</td>
                                        <td className="order-status">{(listitem.DeliveryNo !== null ? <span style={{ color: "green" }}>จัดส่งแล้ว</span> : <span style={{ color: "red" }}>ยังไม่จัดส่ง</span>)}</td>
                                        <td className="order-total"><span>{<NumberFormat value={listitem.TotalAmount} displayType={'text'} thousandSeparator={true} />} บาท</span></td>
                                        <td className="order-action"><a onClick={() => this.onclicks(listitem.OrderNo)} className="btn btn-secondary btn-outline btn-block btn-rounded btn-sm">View</a>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            )
        }
        else {
            var orders = this.state.orderdetailpage;
            var billings = this.state.bliingdetail;
            return (
                <React.Fragment>
                    <div id="orders-view">
                        <h2 className="title text-left pb-1">ข้อมูลการสั่งซื้อ</h2>
                        <div className="order-details">
                            <table className="order-details-table">
                                <thead>
                                    <tr className="summary-subtotal">
                                        <td>
                                            <h3 className="summary-subtitle">การสั่งซื้อ ของคุณ</h3>
                                        </td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="product-subtitle">รายการสินค้า</td>
                                        <td></td>
                                    </tr>
                                    {this.state.orderdetail.map(listitem => {
                                        return listitem.ItemNo > 0 ? <React.Fragment>
                                            <tr key={listitem.id}>
                                                <td className="product-name">{listitem.ProductDesc} <span><i
                                                    className="p-icon-times"></i>
                                                    {listitem.Quantity}</span></td>
                                                <td className="product-price">{listitem.TotalAmount} บาท</td>
                                            </tr>

                                        </React.Fragment> : ""
                                    })}
                                    <tr className="summary-subtotal">
                                        <td>
                                            <h4 className="summary-subtitle">ราคาทั้งหมด:</h4>
                                        </td>
                                        <td className="summary-value font-weight-normal">{orders.TotalAmount} บาท</td>
                                    </tr>
                                    <tr className="summary-subtotal">
                                        <td>
                                            <h4 className="summary-subtitle">การจ่าย:</h4>
                                        </td>
                                        <td className="summary-value">{orders.PaymentStatus !== 0 ? <span style={{ color: "green" }}>จ่ายแล้ว</span>
                                            : <a onClick={() => this.onclickspayagain()} className="btn btn-danger btn-sm back-order"><i
                                                className="p-icon-arrow-long-left ml-0 mr-1"></i>ทำการจ่ายอีกครั้ง</a>}</td>
                                    </tr>
                                    <tr className="summary-subtotal">
                                        <td>
                                            <h4 className="summary-subtitle">ราคาสุทธิ:</h4>
                                        </td>
                                        <td>
                                            <p className="summary-total-price">{orders.TotalAmount}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="row mt-9">
                            <div className="col-sm-6">
                                <div className="card card-address">
                                    <div className="card-body">
                                        <h5 className="card-title lh-2 mb-2">สถานที่จัดส่งบิล</h5>

                                        {billings != null ? <React.Fragment>
                                            <p>{billings.Name1}<br />
                                                {billings.DeliveryAddress}<br />
                                            </p>
                                        </React.Fragment>
                                            : "ไม่มีข้อมูล"}

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card card-address">
                                    <div className="card-body">
                                        <h5 className="card-title lh-2 mb-2">สถานที่ส่งสินค้า</h5>
                                        {billings != null ? <React.Fragment>
                                            <p>{billings.Name1}<br />
                                                {billings.DeliveryAddress}<br />
                                            </p>
                                            ขนส่งบริษัท {billings.DeliverCompany}<br />
                                            <p>Tracking No: {billings.TrackingNo}</p> </React.Fragment>
                                            : "ไม่มีข้อมูล"}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mt-0 mb-6" />
                        <a onClick={() => this.onclickscancel()} className="btn btn-dark btn-sm back-order"><i
                            className="p-icon-arrow-long-left ml-0 mr-1"></i>กลับไปที่รายการ</a>
                    </div>

                </React.Fragment>

            )
        }
    }
}

export default App;
