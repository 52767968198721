import React, { Component, Fragment } from "react";
import { css } from "@emotion/core";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/style.min.css'
import './css/demo3.min.css'
import './css/tree.css'
import NumberFormat from 'react-number-format';
import { Display } from "react-bootstrap-icons";
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cartlist: [],
            summaryprice: 0,
            sumpv: 0,
            mobile: "none"
        }
    }

    componentDidMount() {
        const { userAgent } = navigator
        var cart_list = localStorage.getItem('cart_list');
        console.log(cart_list);    
        if (window.innerWidth < 600) {
            console.log( window.innerWidth);
            this.setState({
                mobile: "",
            });
        }
        if (cart_list != null) {
            this.summarylist(JSON.parse(cart_list));
            this.setState({
                cartlist: JSON.parse(cart_list),
            });
        }
        else {
            // window.location.href = '/home';
        }

    }

    summarylist(cartlist) {
        var countsummary = 0;
        var sumpv = 0;
        cartlist.forEach(items => {
            countsummary = countsummary + (items.productqty * items.price);
            sumpv = sumpv + (items.productqty * items.PV);
        });
        this.setState({
            summaryprice: countsummary,
            sumpv: sumpv,
        });
    }

    delete = (itemslist) => {
        var cartlist = this.state.cartlist
        cartlist.forEach(items => {
            if (items.id == itemslist.id) {
                cartlist.pop(items)
            }
        });
        this.setState({
            cartlist: cartlist,
        });
        localStorage.setItem('cart_list', JSON.stringify(cartlist))
    }

    render() {
        var cartlist = this.state.cartlist
        var mobile = this.state.mobile
        if (cartlist.length > 0) {
            return (

                <div className="dropdown cart-dropdown off-canvas">
                    <a href="#" className="cart-toggle link">
                        <div style={{ display: mobile }}>
                            {this.state.cartlist.length > 0 ?
                                <span>
                                    <span className="cart-count not-badge">{this.state.cartlist.length}</span> Items:
                                    <span className="cart-price"> {<NumberFormat value={this.state.summaryprice} displayType={'text'} thousandSeparator={true} />} บาท</span>
                                </span> : ""
                            }
                        </div>
                        <i className="p-icon-cart-solid">
                        </i>
                        <span className="cart-label">
                            ตะกร้าของฉัน
                            {this.state.cartlist.length > 0 ?
                                <span>
                                    <span className="cart-count not-badge">{this.state.cartlist.length}</span> Items:
                                    <span className="cart-price"> {<NumberFormat value={this.state.summaryprice} displayType={'text'} thousandSeparator={true} />} บาท</span>
                                </span> : ""
                            }
                        </span>
                    </a>
                    <div className="canvas-overlay"></div>
                    <div className="dropdown-box">
                        <div className="canvas-header">
                            <h4 className="canvas-title">ตะกร้าสินค้า</h4>
                            <a href="#" className="btn btn-dark btn-link btn-icon-right btn-close">close<i className="p-icon-arrow-long-right"></i><span className="sr-only">Cart</span></a>
                        </div>
                        <div className="products scrollable">

                            {this.state.cartlist.map(listitem => (
                                <div key={listitem.id} className="product product-mini">
                                    <figure className="product-media">
                                        <a href={"/product?code=" + listitem.productCode}>
                                        </a>
                                        <a href="#" title="Remove Product" onClick={() => this.delete(listitem)} className="btn-remove">
                                            <i className="p-icon-times"></i><span className="sr-only">Close</span>
                                        </a>
                                    </figure>
                                    <div className="product-detail">
                                        <a href={"/product?code=" + listitem.productCode} className="product-name">{listitem.productName} </a>
                                        {+listitem.PV} คะแนน
                                        <div className="price-box">
                                            <span className="product-quantity">{listitem.productqty}</span>
                                            <span className="product-price">{<NumberFormat value={listitem.price * listitem.productqty} displayType={'text'} thousandSeparator={true} />}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="cart-total">
                            <label>รวมทั้งหมด:</label>
                            <span className="price">{<NumberFormat value={this.state.summaryprice} displayType={'text'} thousandSeparator={true} />} บาท</span>
                        </div>
                        <div className="cart-total">
                            <label>คะแนนรวม:</label>
                            <span className="price" style={{ color: "#FF6347" }}>{<NumberFormat value={this.state.sumpv} displayType={'text'} thousandSeparator={true} />} บาท</span>
                        </div>

                        <div className="cart-action">
                            <a href="/cart" className="btn btn-outline btn-dim mb-2 ">ดูตะกร้า</a>
                            <a href="/checkout" className="btn btn-dim cartcheckout" style={{ color: "black" }}><span>ยินยันตะกร้าสินค้า</span></a>
                        </div>

                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="dropdown cart-dropdown off-canvas">
                    <a href="#" className="cart-toggle link">
                        <i className="p-icon-cart-solid">
                        </i>
                        <span className="cart-label">
                            My Cart
                        </span>
                    </a>
                </div>);
        }
    }
}

export default App;
