import React, { Component, Fragment } from "react";
import logo from './logo.svg';
// import './App.css';
//import axios from 'axios';
import instance from "./axios-instance";
import Pagination from "react-js-pagination";
import Login from './login/Loginxx';
import { css } from "@emotion/core";
import jwt_decode from "jwt-decode";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/tree.css'
import './css/style.min.css'
import './css/demo3.min.css'
import BinTree from './bintree';
import ManagePoint from './ManagePoint';
import Orderlist from './order/orderlist';
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            treelist: [],
        }
    }
    validateLogout = (e) => {
        localStorage.removeItem('auth_token')
        localStorage.removeItem('cart_list')
    }
    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            window.location.href = "/home";
        } else {
            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.href = "/home";
        }
    }

    render() {
        return (
            <div className="main account-page">
                <nav className="breadcrumb-nav has-border">                    
                    <div className="container">                   
                        <ul className="breadcrumb">
                            <li><a href="/main">หน้าหลัก</a></li>
                            <li>จัดการคะแนน</li>
                        </ul>
                    </div>
                </nav>
                <div className="page-content mt-4 mb-10 pb-6">
                    <div className="container">
                        <ManagePoint />
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
