import React from 'react';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'

export default function MyLoader({ active }) {
  return (
  < div id='dexloadingx' className={(!active)? '':'dexloadingx'} >
    <div id='dexloading' >
      <LoadingOverlay
        active={active}
        spinner={<BounceLoader />}
      >
      </LoadingOverlay>
    </div >
    </div >
  )
}