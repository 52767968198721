import React, { Component, Fragment } from "react";
import instance from "./axios-instance";
import { css } from "@emotion/core";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/demo3.min.css'
import './css/tree.css'
import image1 from './img/1.png'
//import image2 from './img/5.png'
import image4 from './img/4.png'
import image5 from './img/7.png'
import image6 from './img/6.png'
import imageH1 from './img/1H.png'
import imageH2 from './img/2H.png'
import image3 from './img/2.png'
import image7 from './img/3.png'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import BinaryTreeNew from "family-binary-tree";
import jwt_decode from "jwt-decode";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//
const liff = window.liff;
const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            treelist: [],
            point: 0,
            cash: 0,
            list: [],
            PointL: 0,
            PointR: 0,
            CountL: 0,
            CountR: 0,
            listdata: [],
            data: null,
            profiledetail: [],
        }
    }
    async getProfile() {
        console.log('Profile 2')
        var token = localStorage.getItem('auth_token');
        const { userAgent } = navigator
        await liff.getProfile().then(async dataInfo => {
            console.log(dataInfo);
            await instance.post("/check_user", {
                username: token,
                userAgent: userAgent,
                userLineID: dataInfo.userId,
            }).then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {

                        this.setState({
                            point: res.data.resdata.WalletPoint,
                            cash: res.data.resdata.WalletCash,
                            profiledetail: res.data.resdata,
                        });
                    }


                }
                else {
                }
            });
            this.setState({
                name: dataInfo.displayName,
                userLineID: dataInfo.userId,
                pictureUrl: dataInfo.pictureUrl,
                statusMessage: dataInfo.statusMessage
            });
        });


        //  var token = localStorage.getItem('auth_token');
        //  console.log(token, 85);
        //  console.log(this.state.profiledetail.UserName, 86);
        localStorage.setItem('auth_token', this.state.profiledetail.UserName)
        if (this.state.profiledetail.UserName) {
            //  var decoded = jwt_decode(token);
            var Username = this.state.profiledetail.UserName;
            this.rechecklogin(Username);
            this.setState({
                user_id: token,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
        const languageDevice = liff.getLanguage();
        const versionSDK = liff.getVersion();
        const client = liff.isInClient();
        const isLogin = liff.isLoggedIn();
        const os = liff.getOS();

        this.setState({
            languageDevice: languageDevice,
            versionSDK: versionSDK,
            client: (client === true) ? 'YES' : 'NO',
            isLogin: (isLogin === true) ? 'Login' : 'Not Login',
            os: os
        });
    }
    closeLIFF() {
        liff.closeWindow();
    }
    async componentDidMount() {
    
       // var token = localStorage.getItem('auth_token');
        const { userAgent } = navigator
        console.log(userAgent.includes('Line'))
        if (userAgent.includes('Line')) {
            console.log('Line')
            liff.init({ liffId: '1661498943-bkaAk7p0' })
                .then(async () => {
                    if (!liff.isLoggedIn()) {
                        liff.login();
                    }
                    await this.getProfile();
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
        else {
            console.log('Normal')
            var token = localStorage.getItem('auth_token');
            if (token) {
                var Username = token;
                this.validateLoginForm();
                this.rechecklogin(token);
                this.setState({
                    user_id: token,
                    loginstate: true,
                });
            }
            else {
                this.setState({
                    loginstate: false,
                });
            }
        }

    }
    validateLoginForm = (e) => {
        var token = localStorage.getItem('auth_token');
        console.log(token);
        const { userAgent } = navigator
        instance.post("/check_user", {
            username: token,
            type: "bintree",
            userLineID: undefined,
            userAgent: liff.isInClient() + "/" + userAgent,
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.resdata !== null) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        profiledetail: res.data.resdata
                    });
                }
            }
        });
    }

    rechecklogin(token) {
        instance.post("/check_root", {
            username: token,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        // console.log(res.data.resdata);
                        this.setState({
                            list: res.data.resdata,
                        });
                        // console.log(res.data.resdata);
                        this.gettree();
                    }
                }
                else {
                }
            });
    }
    search(nameKey, position, SponsorLevel, myArray) {
        //  console.log(nameKey, position);
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].MemberCodePlacementRef === nameKey && position === myArray[i].PlacementPosition) {
                return myArray[i];
            }
        }
    }
    gettree() {
        var listitem = this.state.list;
        let lisub = []
        let ulroot = []
        var countL = 0;
        var countR = 0;
        var pointL = 0;
        var pointR = 0;
        var i = 1;
        var lv = 0;
        var profile = this.state.profiledetail
        //console.log(listitem);
        listitem.forEach(items => {
            console.log(items);
            var id = items.MemberCodeRef;
            var username = items.Name1;
            var left_child_id = null;
            var right_child_id = null;
            var member = items.MemberCode;
            var image = image1;
            var WalletPoint = items.WalletPoint;
            var Typeuser = items.MemberCodeSeq
            var PositionL = items.PositionL;
            var PositionR = items.PositionR;
            var rightcount =0;
            var leftcount =0;
            if (member == profile.MemberCode) {
                if (items.WalletPoint >= 300) image = image3;
                else image = image7;

                if (items.Flag == "H" && items.WalletPoint >= 50) image = imageH2;
                else if (items.Flag == "H") image = imageH1;

            }
            else {

                if (items.WalletPoint >= 300) image = image6;
                else if (items.WalletPoint >= 150 && items.WalletPoint < 240) image = image4;
                else if (items.WalletPoint >= 240 && items.WalletPoint < 300) image = image5;
                else image = image1;

                if (items.Flag == "H" && items.WalletPoint >= 50) image = imageH2;
                else if (items.Flag == "H") image = imageH1;
            }
            var sponsrorlv = items.SponsorLevel - 1;
            var MemberCode = items.MemberCode;
            var resultObject = this.search(id, "R", sponsrorlv, listitem);
            if (typeof (resultObject) !== 'undefined') {
                if (resultObject.WalletPoint >= 300) {
                    countR = countR + 1;
                    pointR = pointR + 300;
                }
                var position = resultObject.PlacementPosition;
                if (position == "R") {
                    right_child_id = resultObject.MemberCodeRef;
                }
                else if (position == "L") {
                    left_child_id = resultObject.MemberCodeRef;
                }
            }

            var resultObject = this.search(id, "L", sponsrorlv, listitem);
            if (typeof (resultObject) !== 'undefined') {
                if (resultObject.WalletPoint >= 300) {
                    pointL = pointL + 300;
                    countL = countL + 1;
                }

                var position = resultObject.PlacementPosition;
                if (position == "R") {
                    right_child_id = resultObject.MemberCodeRef;
                }
                else if (position == "L") {
                    left_child_id = resultObject.MemberCodeRef;

                }
            }

            var visible = "none";
            if (member == profile.MemberCode) {
                if (WalletPoint < 300) visible = "flex";
                if (items.Flag == "H" && items.WalletPoint >= 50) visible = "none";
            }
            var rightcount =countR;
            var leftcount =countL;
            var obj = { id, left_child_id, right_child_id, username, image, MemberCode, WalletPoint, visible, Typeuser, PositionL, PositionR,rightcount,leftcount }
            lisub.push(obj);
        });

        lisub.forEach(items => {
            if (items.left_child_id === null) {
                items.left_child_id = items.id + "/L"
                var id = items.id + "/L";
                var MemberCode = items.MemberCode;
                var username = "New";
                var left_child_id = null;
                var right_child_id = null;
                var image = "http://simpleicon.com/wp-content/uploads/add-user.png";
                var obj = { id, left_child_id, right_child_id, username, image, MemberCode }
                ulroot.push(obj);
            }

            if (items.right_child_id === null) {

                items.right_child_id = items.id + "/R"
                var id = items.id + "/R";
                var MemberCode = items.MemberCode;
                var username = "New";
                var left_child_id = null;
                var right_child_id = null;
                var image = "http://simpleicon.com/wp-content/uploads/add-user.png";
                var obj = { id, left_child_id, right_child_id, username, image, MemberCode }
                ulroot.push(obj);
            }

        });

        ulroot.forEach(items => {
            lisub.push(items);
        });
       // console.log(324,lisub);
        this.setState({
            data: lisub,
            CountL: countL,
            CountR: countR,
            PointL: pointL,
            PointR: pointR,
        });
    }
    render() {
        var test = this.state.data;
        if (test !== null) {
            return (

                <div className="test">
                    {/* <TransformWrapper
                        initialScale={1}
                        initialPositionX={0}
                        initialPositionY={0}
                    >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => ( */}
                    <React.Fragment>
                        <div className="filter-actions">
                            <a type="submit" href="/Bintreezoomoutweb" target="_blank" className="btn btn-dim btn-filter">ขยายลำดับขั้น</a>
                        </div>
                        {/* <div className="player-balance">
                            <div className="player-balance">
                                <div className="account-balance balance-wrappers">
                                    <div id="product-balance"><span> ซ้าย : {this.state.CountL} จุด</span></div>
                                    <div id="product-balance"><span> ขวา :  {this.state.CountR} จุด</span></div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="tools">
                                    <button onClick={() => zoomIn()}>+</button>
                                    <button onClick={() => zoomOut()}>-</button>
                                    <button onClick={() => resetTransform()}>x</button>
                                </div> */}
                        {/* <TransformComponent> */}
                        <div className="tree" style={{ zoom: Math.min(width / 1500, height / 1200) }}>
                            <BinaryTreeNew
                                allUsers={test}
                                rootUser={test[0]}
                                bgSideBar={'#333'}
                                colorText={'#333'}
                                colorSideBar={'#fff'}
                                // imageFake={'https://imgur.com/PE0P672.png'}
                                // nameFake={'undefined'}  
                                // bgButton={'blue'}                                    
                                colorButton={'black'}
                            // disableSideBar
                            //disableNavigation
                            // renderNode={user=>{
                            //   return (<div>Nodeฟหกฟหก</div>);
                            // }}
                            // renderDetail={user => {
                            //   return <div>User</div>;
                            // }}
                            />
                        </div>
                        {/* </TransformComponent> */}
                    </React.Fragment>
                    {/* )}
                    </TransformWrapper> */}

                </div>

            )
        }
        else {
            return (
                <React.Fragment>
                </React.Fragment>
            )
        }
    }
}

export default App;
