import React, { Component, Fragment } from "react";
import instance from "./axios-instance";
import { css } from "@emotion/core";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/demo3.min.css'
import './css/tree.css'
import NumberFormat from 'react-number-format';
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cartlist: [],
            profiledetail: null,
            summaryprice: 0,
            otherprice: 0,
            adresseclect: null,
            address: null,
            sumpv: 0,

        }
    }

    componentDidMount() {
        var token = localStorage.getItem('auth_token');
        instance.post("/check_user", {
            type: "checkout",
            username: token

        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.resdata !== null) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        point: res.data.resdata.WalletPoint,
                        cash: res.data.resdata.WalletCash,
                        profiledetail: res.data.resdata,
                        address: res.data.Address,
                        adresseclect: res.data.Address[0],
                    });
                }
            }
            else {
                window.location.href = '/home';
            }
        });


        var cart_list = localStorage.getItem('cart_list');
        if (cart_list != null) {
            this.setState({
                cartlist: JSON.parse(cart_list),
            });
            this.summarylist(JSON.parse(cart_list));
        }
        else {

        }

    }

    summarylist(cartlist) {
        var countsummary = 0;
        var sumpv = 0;
        cartlist.forEach(items => {
            countsummary = countsummary + (items.productqty * items.price);
            sumpv = sumpv + (items.productqty * items.PV);
        });
        this.setState({
            summaryprice: countsummary,
            sumpv: sumpv,
        });
    }
    delete = (itemslist) => {
        var cartlist = this.state.cartlist
        cartlist.forEach(items => {
            if (items.id == itemslist.id) {
                cartlist.pop(items)
            }
        });
        this.setState({
            cartlist: cartlist,
        });
        localStorage.setItem('cart_list', JSON.stringify(cartlist))
    }

    handleChange = (e) => {
        for (const element of this.state.address) {
            if (element.Seq == e.target.value) {
                this.setState({
                    adresseclect: element
                });

            }
        }
    }

    confirmorder = (itemslist) => {
        var cartlist = this.state.cartlist
        var token = localStorage.getItem('auth_token');
        instance.post("/confirm_order", {
            username: token,
            itemlist: cartlist,
            summaryprice: this.state.summaryprice,
            otherprice: this.state.otherprice,
            adress: this.state.adresseclect,
            sumpv: this.state.sumpv
        }).then((res) => {
            if (res.data.status === 200) {
                if (res.data.resdata !== null) {
                    localStorage.removeItem('cart_list');
                    window.location.href = '/orderfinish?orderno=' + res.data.resdata.OrderNo;
                }
            }
            else {
            }
        });
    }
    render() {
        var cartlist = this.state.cartlist
        var pfdetail = this.state.profiledetail;

        const { address, adresseclect } = this.state
        if (cartlist.length > 0 && pfdetail != null) {
            return (
                <div>
                    <main className="main checkout">
                        <div className="page-content pt-8 pb-10 mb-4">
                            <div className="step-by pr-4 pl-4">
                                <h3 className="title title-step "><a href="/cart">1.ตะกร้าสินค้า</a></h3>
                                <h3 className="title title-step active"><a href="/checkout">2.สรุปการสั่งซื้อ</a></h3>
                                <h3 className="title title-step"><a href="#">2.สั่งซื้อสำเร็จ</a></h3>
                            </div>
                            <div className="container mt-7">
                                <form action="#" className="form">
                                    <div className="row">
                                        <div className="col-lg-7 mb-6 mb-lg-0 check-detail">
                                            <h3 className="title text-left mt-3 mb-6">ที่อยู่จัดส่งสินค้า / บิล</h3>

                                            <div className="row">
                                                <div className="col-sm-6 mb-4">
                                                    <label>ชื่อ</label>
                                                    <input type="text" name="Name1" value={pfdetail.Name1 != null ? pfdetail.Name1 : ""} placeholder="" required="" readOnly />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6 mb-4">
                                                    <label>เลือกที่อยู่</label>
                                                    <select id="addressx" name="addressx" onChange={(e) => {
                                                        this.handleChange(e)
                                                    }}>
                                                        {address == null ? <option value="0">ไม่มีข้อมูล</option> : address.map(listitem => (
                                                            <option value={listitem.Seq}>{listitem.AddressNo + " " + listitem.Province + " " + listitem.Postcode}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <label>ที่อยุ่สำหรับจัดส่ง*</label>
                                                <div className="col-sm-6 mb-4">
                                                    <label>บ้านเลขที่</label>
                                                    <input type="text" name="AddressNo" onChange={this.handleChange} value={adresseclect != null ? adresseclect.AddressNo : ""} placeholder="" required="" readOnly />
                                                </div>
                                                <div className="col-sm-6 mb-4">
                                                    <label>หมู่</label>
                                                    <input type="text" name="Mo" onChange={this.handleChange} value={adresseclect != null ? adresseclect.Mo : ""} placeholder="" required="" readOnly />
                                                </div>
                                                <div className="col-sm-6 mb-4">
                                                    <label>แขวง</label>
                                                    <input type="text" name="SubDistrict" onChange={this.handleChange} value={adresseclect != null ? adresseclect.SubDistrict : ""} placeholder="" required="" readOnly />
                                                </div>
                                                <div className="col-sm-6 mb-4">
                                                    <label>เขต</label>
                                                    <input type="text" name="District" onChange={this.handleChange} value={adresseclect != null ? adresseclect.District : ""} placeholder="" required="" readOnly />
                                                </div>
                                                <div className="col-sm-6 mb-4">
                                                    <label>จังหวัด</label>
                                                    <input type="text" name="Province" onChange={this.handleChange} value={adresseclect != null ? adresseclect.Province : ""} placeholder="" required="" readOnly />
                                                </div>
                                                <div className="col-sm-3 mb-4">
                                                    <label>รหัสไปรณีย์</label>
                                                    <input type="text" name="ZipCode" onChange={this.handleChange} value={adresseclect != null ? adresseclect.ZipCode : ""} placeholder="" required="" readOnly />
                                                </div>
                                            </div>

                                            <label>อีเมล์ *</label>
                                            <input type="email" name="email" value={pfdetail.EMail} required="" readOnly />
                                            <label>หมายเหตุ เพิ่มเติม</label>
                                            <textarea className="form-control mb-0" cols="30" rows="5"
                                                placeholder="Write Your Review Here..."></textarea>
                                        </div>
                                        <aside className="col-lg-5 sticky-sidebar-wrapper  pl-lg-6">
                                            <div className="sticky-sidebar" data-sticky-options="{'bottom': 50}">
                                                <div className="summary pt-5">
                                                    <h3 className="title">รายการสินค้า</h3>
                                                    <table className="order-sidebar">
                                                        <thead>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.cartlist.map(listitem => (
                                                                <tr key={listitem.id}>
                                                                    <td className="product-name">{listitem.productName}<span className="product-quantity">×{listitem.productqty}</span></td>
                                                                    <td className="product-total text-body">{<NumberFormat value={listitem.price * listitem.productqty} displayType={'text'} thousandSeparator={true} />} บาท</td>
                                                                </tr>
                                                            ))}
                                                            <tr className="order-last"></tr>
                                                            <tr className="summary-subtotal">
                                                                <td>
                                                                    <h4 className="summary-subtitle">รวมราคา</h4>
                                                                </td>
                                                                <td className="summary-subtotal-price">{<NumberFormat value={this.state.summaryprice} displayType={'text'} thousandSeparator={true} />}  บาท
                                                                </td>
                                                            </tr>
                                                            <tr className="sumnary-shipping shipping-row-last">
                                                                <td colSpan="2" className="pb-3">
                                                                    <h4 className="summary-subtitle pb-3">ขนส่ง</h4>
                                                                    <ul>
                                                                        <li>
                                                                            <input type="radio" defaultChecked id="free-shipping" name="shipping"
                                                                                className="custom-control-input" />
                                                                            <label className="custom-control-label" htmlFor="free-shipping">ฟรีขนส่ง</label>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                            <tr className="order-last"></tr>
                                                            <tr className="summary-total">
                                                                <td>
                                                                    <h4 className="summary-subtitle">ยอดสุทธิ</h4>
                                                                </td>
                                                                <td>
                                                                    <p className="summary-total-price ls-s" style={{ color: "#6bad0d" }}>{<NumberFormat value={this.state.summaryprice} displayType={'text'} thousandSeparator={true} />}  บาท</p>
                                                                </td>
                                                            </tr>
                                                            <tr className="summary-total">
                                                                <td>
                                                                    <h4 className="summary-subtitle">คะแนนรวม</h4>
                                                                </td>
                                                                <td>
                                                                    <p className="summary-total-price ls-s" style={{ color: "#FF6347" }}>{<NumberFormat value={this.state.sumpv} displayType={'text'} thousandSeparator={true} />}  คะแนน</p>
                                                                </td>
                                                            </tr>
                                                            <tr className="order-last"></tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="payment accordion radio-type pb-5">
                                                        <h4 className="summary-subtitle ls-m pb-3">จ่ายผ่าน</h4>
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <a href="#collapse1" className="collapse">จ่ายผ่าน QR Code
                                                                </a>
                                                            </div>
                                                            <div id="collapse1" className="expanded" >
                                                                <div className="card-body">
                                                                    หน้าต่อไปจะมีการแจ้ง QR Code ท่านสามารถแสกนจ่าย เพื่อทำไห้ออเดอร์นี้เสร็จสิ้น
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="card">
                                                            <div className="card-header">
                                                                <a href="#collapse2" className="expand">จ่ายปลายทาง</a>
                                                            </div>
                                                            <div id="collapse2" className="collapsed">
                                                                <div className="card-body">
                                                                    หากท่านต้องการจ่ายปลายทางมีการเรียกเก็บเพิ่มเติม 3%
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <a type="submit" onClick={this.confirmorder} className="btn btn-dim btn-block mt-6 cartcheckout">ยืนยันการซื้อสินค้า</a>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </main>
                </div>
            );
        }
        else {
            return (
                <div className="dropdown cart-dropdown off-canvas">
                    <a href="#" className="cart-toggle link">
                        <i className="p-icon-cart-solid">
                        </i>
                        <span className="cart-label">
                            My Cart
                        </span>
                    </a>
                </div>);
        }
    }
}

export default App;
