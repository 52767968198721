import React, { Component, Fragment } from "react";
import logo from './logo.svg';
// import './App.css';
//import axios from 'axios';
import instance from "./axios-instance";
import { css } from "@emotion/core";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/demo3.min.css'
import './css/tree.css'
import NumberFormat from 'react-number-format';
import system from "./objectdata";
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//
var Productionlink = system.REACT_APP_API_LINKPIC
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cartlist: [],
            summaryprice: 0,
            sumpv: 0,
        }
    }

    componentDidMount() {
        var cart_list = localStorage.getItem('cart_list');
        if (cart_list != null) {
            this.setState({
                cartlist: JSON.parse(cart_list),
            });
            this.summarylist(JSON.parse(cart_list));
        }
        else {
            window.location.href = '/home';
        }

    }
    summarylist(cartlist) {
        var countsummary = 0;
        var sumpv = 0;
        cartlist.forEach(items => {
            countsummary = countsummary + (items.productqty * items.price);
            sumpv = sumpv + (items.productqty * items.PV);
        });
        this.setState({
            summaryprice: countsummary,
            sumpv: sumpv,
        });
    }

    updatecart = () => {
        window.location.reload()
    }

    delete = (itemslist) => {
        var cartlist = this.state.cartlist
        cartlist.forEach(items => {
            if (items.id == itemslist.id) {
                cartlist.pop(items)
            }
        });
        this.setState({
            cartlist: cartlist,
        });
        localStorage.setItem('cart_list', JSON.stringify(cartlist))
        window.location.reload(false);
    }

    updateqty = (state, item) => {
        var cartlist = this.state.cartlist
        cartlist.forEach(items => {
            if (items.id == item.id) {
                if (state === "plus") items.productqty = items.productqty + 1
                else items.productqty = items.productqty - 1
            }
        });
        this.setState({
            cartlist: cartlist,
        });
        this.summarylist(cartlist);
        localStorage.setItem('cart_list', JSON.stringify(cartlist))
        window.location.reload(false);
    }


    render() {
        var cartlist = this.state.cartlist
        if (cartlist.length > 0) {
            return (
                <div>
                    <main className="main cart">
                        <div className="page-content pt-8 pb-10 mb-4">
                            <div className="step-by">
                                <h3 className="title title-step active"><a href="/cart">1.ตะกร้าสินค้า</a></h3>
                                <h3 className="title title-step"><a href="/checkout">2.สรุปการสั่งซื้อ</a></h3>
                                <h3 className="title title-step"><a href="#">2.สั่งซื้อสำเร็จ</a></h3>
                            </div>
                            <div className="container mt-7 mb-2">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12 pr-lg-6">
                                        <table className="shop-table cart-table">
                                            <thead>
                                                <tr>
                                                    <th><span>สินค้า</span></th>
                                                    <th></th>
                                                    <th><span>คะแนน</span></th>
                                                    <th><span>ราคา</span></th>
                                                    <th><span>จำนวน</span></th>
                                                    <th>รวม</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cartlist.map(listitem => (
                                                    <tr key={listitem.id}>
                                                        <td className="product-thumbnail">
                                                            <figure>
                                                                <a href={"/product?code=" + listitem.productCode}>
                                                                    <img src={listitem.Image != null ? listitem.configlink + "/" + listitem.Image : Productionlink + "/Files/Images/default-image.jpg"} width="90" height="112"
                                                                        alt="product" />
                                                                </a>
                                                            </figure>
                                                        </td>
                                                        <td className="product-name">
                                                            <div className="product-name-section">
                                                                <a href={"/product?code=" + listitem.productCode}>{listitem.productName}</a>

                                                            </div>
                                                        </td>
                                                        <td className="product-subtotal">
                                                            <span className="amount">{<NumberFormat value={listitem.PV} displayType={'text'} thousandSeparator={true} />}</span>
                                                        </td>
                                                        <td className="product-subtotal">
                                                            <span className="amount">{<NumberFormat value={listitem.price} displayType={'text'} thousandSeparator={true} />}</span>
                                                        </td>
                                                        <td className="product-quantity">
                                                            <div className="input-group">
                                                                <button onClick={() => this.updateqty("minus", listitem)} className="quantity-minus p-icon-minus-solid"></button>
                                                                <span className="quantity form-control">{listitem.productqty}</span>
                                                                <button onClick={() => this.updateqty("plus", listitem)} className="quantity-plus p-icon-plus-solid"></button>
                                                            </div>
                                                        </td>
                                                        <td className="product-price">
                                                            <span className="amount">{<NumberFormat value={listitem.price * listitem.productqty} displayType={'text'} thousandSeparator={true} />}</span>
                                                        </td>
                                                        <td className="product-remove">
                                                            <a onClick={() => this.delete(listitem)} className="btn-remove" title="Remove this product">
                                                                <i className="p-icon-times"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="cart-actions mb-6 pt-6">
                                            <a href="/home?search=apple" className="btn btn-dim btn-icon-left mr-4 mb-4 cartcheckout"><i
                                                className="p-icon-arrow-long-left cartcheckout"></i>สั่งซื้อเพิ่ม</a>
                                            <button type="submit" onClick={() => this.updatecart()} className="btn btn-outline btn-dim">อัพเดทตะกร้า</button>
                                        </div>
                                    </div>
                                    <aside className="col-lg-4 sticky-sidebar-wrapper">
                                        <div className="sticky-sidebar" data-sticky-options="{'bottom': 20}">
                                            <div className="summary mb-4">
                                                <h3 className="summary-title">Cart Totals</h3>
                                                <table className="shipping mb-2">
                                                    <tbody>
                                                        <tr className="summary-subtotal">
                                                            <td>
                                                                <h4 className="summary-subtitle">ทั้งหมด</h4>
                                                            </td>
                                                            <td>
                                                                <p className="summary-subtotal-price" style={{ color: "#6bad0d" }}>{<NumberFormat value={this.state.summaryprice} displayType={'text'} thousandSeparator={true} />} บาท</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="summary-subtotal">
                                                            <td>
                                                                <h4 className="summary-subtitle">คะแนนรวม</h4>
                                                            </td>
                                                            <td>
                                                                <p className="summary-subtotal-price" style={{ color: "#FF6347" }}>{<NumberFormat value={this.state.sumpv} displayType={'text'} thousandSeparator={true} />} คะแนน</p>
                                                            </td>
                                                        </tr>

                                                        <tr className="sumnary-shipping shipping-row-last">
                                                            <td colSpan="2">
                                                                <h4 className="summary-subtitle pb-3">ขนส่ง</h4>
                                                                <ul>
                                                                    <li>
                                                                        <input type="radio" defaultChecked id="free-shipping" name="shipping"
                                                                            className="custom-control-input" />
                                                                        <label className="custom-control-label" htmlFor="free-shipping">ฟรีขนส่ง</label>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table className="total">
                                                    <tbody>
                                                        <tr className="summary-subtotal">
                                                            <td>
                                                                <h4 className="summary-subtitle">รวมทั้งหมด</h4>
                                                            </td>
                                                            <td>
                                                                <p className="summary-total-price ls-s">{<NumberFormat value={this.state.summaryprice} displayType={'text'} thousandSeparator={true} />} บาท</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <a href="/checkout" className="btn btn-dim btn-checkout btn-block cartcheckout">สรุปการสั่งซื้อ</a>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            );
        }
        else {
            return (
                <div>
                    <main className="main cart">
                        <div className="page-content pt-8 pb-10 mb-4">
                            <div className="step-by">
                                <h3 className="title title-step active"><a href="/cart">1.ตะกร้าสินค้า</a></h3>
                                <h3 className="title title-step"><a href="/checkout">2.สรุปการสั่งซื้อ</a></h3>
                                <h3 className="title title-step"><a href="#">2.สั่งซื้อสำเร็จ</a></h3>
                            </div>
                            <div className="container mt-7 mb-2">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12 pr-lg-6">
                                        <table className="shop-table cart-table">
                                            <thead>
                                                <tr>
                                                    <th><span>สินค้า</span></th>
                                                    <th></th>
                                                    <th><span>คะแนน</span></th>
                                                    <th><span>ราคา</span></th>
                                                    <th><span>จำนวน</span></th>
                                                    <th>รวม</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                        <div className="cart-actions mb-6 pt-6">
                                            <a href="/home?search=apple" className="btn btn-dim btn-icon-left mr-4 mb-4 cartcheckout"><i
                                                className="p-icon-arrow-long-left cartcheckout"></i>สั่งซื้อเพิ่ม</a>
                                            <button type="submit" onClick={() => this.updatecart()} className="btn btn-outline btn-dim">อัพเดทตะกร้า</button>
                                        </div>
                                    </div>
                                    <aside className="col-lg-4 sticky-sidebar-wrapper">
                                        <div className="sticky-sidebar" data-sticky-options="{'bottom': 20}">
                                            <div className="summary mb-4">
                                                <h3 className="summary-title">Cart Totals</h3>
                                                <table className="shipping mb-2">
                                                    <tbody>
                                                        <tr className="summary-subtotal">
                                                            <td>
                                                                <h4 className="summary-subtitle">ทั้งหมด</h4>
                                                            </td>
                                                            <td>
                                                                <p className="summary-subtotal-price" style={{ color: "#6bad0d" }}>{<NumberFormat value={this.state.summaryprice} displayType={'text'} thousandSeparator={true} />} บาท</p>
                                                            </td>
                                                        </tr>
                                                        <tr className="summary-subtotal">
                                                            <td>
                                                                <h4 className="summary-subtitle">คะแนนรวม</h4>
                                                            </td>
                                                            <td>
                                                                <p className="summary-subtotal-price" style={{ color: "#FF6347" }}>{<NumberFormat value={this.state.sumpv} displayType={'text'} thousandSeparator={true} />} คะแนน</p>
                                                            </td>
                                                        </tr>

                                                        <tr className="sumnary-shipping shipping-row-last">
                                                            <td colSpan="2">
                                                                <h4 className="summary-subtitle pb-3">ขนส่ง</h4>
                                                                <ul>
                                                                    <li>
                                                                        <input type="radio" defaultChecked id="free-shipping" name="shipping"
                                                                            className="custom-control-input" />
                                                                        <label className="custom-control-label" htmlFor="free-shipping">ฟรีขนส่ง</label>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table className="total">
                                                    <tbody>
                                                        <tr className="summary-subtotal">
                                                            <td>
                                                                <h4 className="summary-subtitle">รวมทั้งหมด</h4>
                                                            </td>
                                                            <td>
                                                                <p className="summary-total-price ls-s">{<NumberFormat value={this.state.summaryprice} displayType={'text'} thousandSeparator={true} />} บาท</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <a href="/checkout" className="btn btn-dim btn-checkout btn-block cartcheckout">สรุปการสั่งซื้อ</a>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>);
        }
    }
}

export default App;
