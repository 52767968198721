import React, { Component } from 'react';
import logo from './logo.svg';
// import './App.css';
import axios from 'axios';
import Pagination from "react-js-pagination";
import Login from './login/Loginxx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { css } from "@emotion/core";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import MyLoader from './Loading'
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import system from "./objectdata";
var Productionlink =system.REACT_APP_API_LINK 
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//
class App extends Component {

    constructor(props) {
        super(props)
        this.state = {
            productInter: null,
            productNew: null,
            databanner: null,
            cash: 0,
            profiledetail: null,
            search: null,
            productlist: [],
            configlink: null
        }
    }

    componentDidMount() {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        var search = params.get("search");
        var token = localStorage.getItem('auth_token');
        if (search === null) {
            instance.post("/porduct_fristpage", {
                username: token
            }).then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata_newproduct !== null) {
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            productInter: res.data.resdata_interrest
                        });
                    }

                    if (res.data.resdata_newproduct !== null) {
                        // console.log(res.data.resdata_newproduct);
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            productNew: res.data.resdata_newproduct,
                            productlist: res.data.resdata_newproduct,
                            configlink: res.data.configlink
                        });
                    }
                    console.log(res.data.resdata_banner, '12112');
                    if (res.data.resdata_banner !== null) {

                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            databanner: res.data.resdata_banner
                        });
                    }



                }
                else {
                }
            });
        }
        else {
            this.setState({
                search: search,
            });
            instance.post("/product_list", {
                productdesc: search,
                username: token
            }).then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata_newproduct !== null) {
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            productlist: res.data.resdata,
                            configlink: res.data.configlink
                        });
                    }
                }
                else { }
            });
        }

        var token = localStorage.getItem('auth_token');
        if (token != null) {
            // this.rechecklogin(Username);
            this.setState({
                user_id: token,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }
    addcart = (product) => {
        var cart_list = localStorage.getItem('cart_list');
        var configlink = this.state.configlink;
        console.log(product.Path);
        var carttlistobj = []
        if (cart_list != null) var carttlistobj = JSON.parse(cart_list);
        var price = product.ProductPrice;
        var productCode = product.ProductCode;
        var productqty = 1;
        var productName = product.ProductDesc;
        var id = carttlistobj.length + 1
        var PV = product.ProductPv
        var Image = product.Path
        var cartlists = { price, productCode, productqty, productName, id, PV, Image, configlink }

        var checked = false;
        carttlistobj.forEach(items => {
            if (items.productCode == product.ProductCode) {
                items.productqty += productqty
                checked = true;
            }
        });

        if (!checked) carttlistobj.push(cartlists);
        this.setState({
            cartlist: carttlistobj,
        });
        localStorage.setItem('cart_list', JSON.stringify(carttlistobj))        
        window.location.reload(false);
    }


    render() {
        console.log(this.state.productInter);
        var objectproduct = this.state.productInter;
        var objectproductnew = this.state.productNew;
        var objectbanner = this.state.databanner;
        if (this.state.search === null) {
            return (
                <div>
                    <div className="header-bottom sticky-header fix-top sticky-content has-dropdown d-none d-lg-flex">
                        <div className="container">
                            <div className="header-left">
                                <div className="dropdown category-dropdown menu-fixed with-sidebar has-border">
                                    <a href="#" className="text-white category-toggle"><i className="p-icon-bars-solid"></i><span
                                        className="text-uppercase font-weight-normal">หมวดสินค้า</span></a>
                                    <div className="dropdown-box">
                                        <ul className="menu vertical-menu category-menu d-lg-show">
                                            <li className="submenu">
                                                <a href="/home?search=Apple"><i className="p-icon-fruit"></i>ทั่วไป</a>
                                                <div className="megamenu">
                                                    <div className="row">
                                                        <div className="col-6 col-sm-4">
                                                            <h4 className="menu-title title-underline"><span>ชนิดสินค้า</span>
                                                            </h4>
                                                            <ul>
                                                                <li><a href="/home?search=ชนิดน้ำ">ชนิดน้ำ</a></li>
                                                                <li><a href="/home?search=ชนิดแคปซูล">ชนิดแคปซูล</a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-6 col-sm-4">
                                                            <h4 className="menu-title title-underline"><span>สินค้าพิเศษ</span></h4>
                                                            <ul>
                                                                <li><a href="/home?search=พิเศษ">สินค้าพิเศษ</a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-6 col-sm-4 menu-banner banner banner-fixed">
                                                            <figure>
                                                                <img src="images/demos/demo3/menu/1.jpg" alt="Menu banner" width="210" height="359" />
                                                            </figure>
                                                            <div className="banner-content">
                                                                <h3 className="banner-title mb-2">
                                                                    สินค้าเพื่อสุขภาพ <br />โดยธรรมชาติ
                                                                </h3>
                                                                <a href="/home?search=Apple" className="btn btn-link btn-primary-dark pl-0 font-weight-normal">สั่งซื้อตอนนี้<i className="p-icon-arrow-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            {/* <li className="submenu">
                                                <a href="/home?search=Apple"><i className="p-icon-drink"></i>โปรโมชั่น
                                                </a>
                                                <div className="megamenu" style={{ minWidth: "50.5rem" }} >
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h4 className="menu-title title-underline"><span>โปรโมชั่น</span>
                                                            </h4>
                                                            <ul>
                                                                <li><a href="/home?search=ลดราคา">สินค้าพิเศษ</a></li>
                                                                <li><a href="/home?search=สินค้าโปรโมชั่นอาทิตย์นี้">สินค้าโปรโมชั่นอาทิตย์นี้</a></li>
                                                                <li><a href="/home?search=สินค้าโปรโมชั่นสิ้นปี">สินค้าโปรโมชั่นสิ้นปี</a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-6 menu-banner banner banner-fixed">
                                                            <figure>
                                                                <img src="images/demos/demo3/menu/4.jpg" alt="Menu banner" width="210" height="323" />
                                                            </figure>
                                                            <div className="banner-content">
                                                                <h4 className="text-secondary text-uppercase mb-1">กำลังมาแรง</h4>
                                                                <h3 className="banner-title mb-2 text-black">
                                                                    เฟมิน่าชนิดน้ำ 750<br />ขวดพลาสติก
                                                                </h3>
                                                                <a href="/home?search=Apple" className="btn btn-link btn-primary-dark pl-0 font-weight-normal">สั่งซื้อตอนนี้<i className="p-icon-arrow-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <nav className="main-nav">
                                    <ul className="ml-4 menu menu-type2">
                                        <li className="active">
                                            <a href="/">หน้าหลัก</a>
                                        </li>
                                        <li>
                                            <a href="/home?search=Apple">สินค้าขายดี</a>
                                            <div className="megamenu">
                                            </div>
                                        </li>
                                        <li>
                                            <a href="#">อื่นๆ</a>
                                            <div className="megamenu">
                                            </div>
                                        </li>
                                        <li>
                                        </li>
                                        <li>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <main className="main">
                        <div className="page-content">
                            <div className="container">
                                <div className="row">
                                    <aside className="col-aside sidebar sidebar-fixed sticky-sidebar-wrapper">
                                        <div className="sidebar-overlay">
                                        </div>
                                        <a className="sidebar-close" href="#"><i className="p-icon-times"></i></a>
                                        <a href="#" className="sidebar-toggle"><i className="fas fa-chevron-right"></i></a>
                                        <div className="sidebar-content">
                                            <div className="sticky-sidebar" data-sticky-options="{'top': 67}">

                                                <div className="icon-boxes mt-8">
                                                    <div className="icon-box icon-box-side">
                                                        <span className="icon-box-icon">
                                                            <i className="p-icon-shipping-solid"></i>
                                                        </span>
                                                        <div className="icon-box-content">
                                                            <h4 className="icon-box-title">ส่งฟรี</h4>
                                                            <p>ส่งฟรีถ้าสั่งเกิน 1000 บาท ขึ้นไป</p>
                                                        </div>
                                                    </div>
                                                    <div className="icon-box icon-box-side">
                                                        <span className="icon-box-icon">
                                                            <i className="p-icon-quality"></i>
                                                        </span>
                                                        <div className="icon-box-content">
                                                            <h4 className="icon-box-title">รับประกันคุณภาพสินค้า</h4>
                                                            <p>ผลิตภัณฑ์คุณภาพ</p>
                                                        </div>
                                                    </div>
                                                    <div className="icon-box icon-box-side">
                                                        <span className="icon-box-icon">
                                                            <i className="p-icon-fax2"></i>
                                                        </span>
                                                        <div className="icon-box-content">
                                                            <h4 className="icon-box-title">ปลอดภัย รวดเร็ว</h4>
                                                            <p>สามารถจ่ายเงินได้หลายแบบ รวดเร็ว!</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h3 className="mb-3 mt-7">สินค้ามาแรง</h3>
                                                <div className="widget widget-products">
                                                    <div className="widget-body pt-0">
                                                            <div className="owl-carousel owl-nav-top row cols-1" data-owl-options="{
                                                  'items': 1,
                                                  'loop': false,
                                                  'nav': true,
                                                  'dots': false
                                              }">
                                                                <div className="products-col">
                                                                    {objectproduct !== null && objectproduct !== undefined ? objectproduct.map(listitem => (
                                                                        <div key={listitem.ProductCode} className="product product-list-sm mb-4">
                                                                            <figure className="product-media">
                                                                                <a href="#">
                                                                                    <img src={listitem.Path !== null ? this.state.configlink + "/" + listitem.Path : Productionlink + "/Files/Images/default-image.jpg"} alt="product" width="124" height="155" />
                                                                                    <img src={listitem.Path !== null ? this.state.configlink + "/" + listitem.Path : Productionlink + "/Files/Images/default-image.jpg"} alt="product" width="124" height="155" />
                                                                                </a>
                                                                            </figure>
                                                                            <div className="product-details">
                                                                                <h5 className="product-name">
                                                                                    <a href={"/product?code=" + listitem.ProductCode}>
                                                                                        {listitem.ProductDesc}
                                                                                    </a>
                                                                                </h5>
                                                                                <span className="product-price">
                                                                                    <ins className="new-price">{listitem.ProductPrice} บาท</ins>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )) : ""}
                                                                </div>
                                                            </div>
                                                                 
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </aside>
                                    <div className="col-main col-lg-8 mt-4">
                                        <section className="intro-section">
                                            <div className="owl-dots-line">
                                                <div className="owl-carousel owl-theme row cols-1" data-owl-options="{
                                          'items': 1,
                                          'dotsContainer': '.owl-dots-container',
                                          'dots': true,
                                          'nav': false
                                      }">
                                                    <div className="banner banner-fixed banner1">
                                                        <figure>
                                                            <img src="images/CT102.jpg" alt="banner" width="1015" height="449"
                                                                style={{ backgroundColor: " #f1f1e4" }} />
                                                        </figure>
                                                        <div className="banner-content y-50 text-right">
                                                            <h2 className="banner-title text-uppercase mb-2">
                                                                {/* Enjoy your<br />favorite thing */}
                                                            </h2>
                                                            {/* <a href="/home?search=Apple"  className="btn btn-outline btn-dark mr-1 superblack">สั่งเลย<i className="p-icon-arrow-long-right"></i></a> */}
                                                            <div className="floating p-absolute" data-options='{
                                                      "invertX":false,
                                                      "invertY":false
                                                  }' data-floating-depth=".5">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {objectbanner != null && objectbanner != undefined ?
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <div className="banner banner4 banner-fixed mt-4 overlay-effect2">
                                                            <figure>

                                                                <img src={objectbanner[0] === undefined ? Productionlink + "/Files/Images/default-image.jpg" : objectbanner[0].Path !== null ? this.state.configlink + "/" + objectbanner[0].Path : Productionlink + "/Files/Images/default-image.jpg"} alt="banner" width="498" height="228"
                                                                    style={{ backgroundColor: " #cfd5c1" }} />

                                                                {/* <img src="images/demos/demo3/banners/2.jpg" alt="banner" width="498" height="228"
                                                                style={{ backgroundColor: " #cfd5c1" }} /> */}
                                                            </figure>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="banner banner5 banner-fixed mt-4 overlay-effect2">
                                                            <figure>
                                                                <img src={objectbanner[1] === undefined ? Productionlink + "/Files/Images/default-image.jpg" : objectbanner[1].Path !== null ? this.state.configlink + "/" + objectbanner[1].Path : Productionlink + "/Files/Images/default-image.jpg"} alt="banner" width="498" height="228"
                                                                    style={{ backgroundColor: " #cfd5c1" }} />
                                                                {/* <img src="images/demos/demo3/banners/3.jpg" alt="banner" width="498" height="228"
                                                                    style={{ backgroundColor: " #f7eeef" }} /> */}
                                                            </figure>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="banner banner5 banner-fixed mt-4 overlay-effect2">
                                                            <figure>

                                                                <img src={objectbanner[2] === undefined ? Productionlink + "/Files/Images/default-image.jpg" : objectbanner[2].Path !== null ? this.state.configlink + "/" + objectbanner[2].Path : Productionlink + "/Files/Images/default-image.jpg"} alt="banner" width="498" height="228"
                                                                    style={{ backgroundColor: " #cfd5c1" }} />
                                                                {/* <img src="images/demos/demo3/banners/3.jpg" alt="banner" width="498" height="228"
                                                                    style={{ backgroundColor: " #f7eeef" }} /> */}
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div> : ""
                                            }

                                        </section>

                                        <section>
                                            <div className="tab product-tab-type4 mt-10 mb-2 mb-md-0 pt-6 ">
                                                <div className="d-flex justify-content-between">
                                                    <h2 className="title mb-4">สินค้าเพิ่มใหม่</h2>
                                                    <ul className="nav nav-tabs ml-auto">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" href="#fruits">
                                                                <div className="nav-title">ทั่วไป</div>
                                                            </a>
                                                        </li>
                                                    </ul>

                                                </div>
                                                <div className="tab-content">
                                                    <div className="tab-pane active" id="fruits">
                                                        {objectproductnew != null ?
                                                            <div className="owl-carousel owl-theme row cols-md-3 cols-2" data-owl-options="{
                                                  'nav': false,
                                                  'dots': true,
                                                  'margin': 20,
                                                  'loop': false,
                                                  'responsive': {
                                                      '0': {
                                                          'items': 2
                                                      },
                                                      '768': {
                                                          'items': 3
                                                      }
                                                  }
                                              }">

                                                                <div className="product shadow-media text-center">
                                                                    <figure className="product-media">
                                                                        <a href={"/product?code=" + objectproductnew[0].ProductCode}>
                                                                            <img src={objectproductnew[0].Path !== null ? this.state.configlink + "/" + objectproductnew[0].Path : Productionlink + "/Files/Images/default-image.jpg"} alt="product" width="240" height="300" />
                                                                            <img src={objectproductnew[0].Path !== null ? this.state.configlink + "/" + objectproductnew[0].Path : Productionlink + "/Files/Images/default-image.jpg"} alt="product" width="240" height="300" />
                                                                        </a>
                                                                        <div className="product-label-group">
                                                                        </div>
                                                                        <div className="product-action-float">
                                                                            <a className="btn-product-icon btn-cart" style={{ cursor: "pointer " }} onClick={() => this.addcart(objectproductnew[0])} title="เพิ่มเข้าตะกร้า">
                                                                                <i className="p-icon-cart-solid"></i>
                                                                            </a>
                                                                            <a href="#" className="btn-product-icon btn-wishlist" title="Add to Wishlist">
                                                                                <i className="p-icon-heart-solid"></i>
                                                                            </a>
                                                                            {/* <a href="#" className="btn-product-icon btn-quickview" title="Quick View">
                                                                                <i className="p-icon-search-solid"></i>
                                                                            </a> */}
                                                                        </div>
                                                                    </figure>
                                                                    <div className="product-details">
                                                                        <h5 className="product-name">
                                                                            <a href={"/product?code=" + objectproductnew[0].ProductCode}>
                                                                                {objectproductnew[0].ProductDesc}
                                                                            </a>
                                                                        </h5>
                                                                        <span className="product-price">
                                                                            {/* <del className="old-price">$20.00</del> */}
                                                                            <ins className="new-price">  {objectproductnew[0].ProductPrice} บาท</ins>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="product shadow-media text-center">
                                                                    <figure className="product-media">
                                                                        <a href={"/product?code=" + objectproductnew[1].ProductCode}>
                                                                            <img src={objectproductnew[1].Path !== null ? this.state.configlink + "/" + objectproductnew[1].Path : Productionlink + "/Files/Images/default-image.jpg"} alt="product" width="240" height="300" />
                                                                        </a>
                                                                        {/* <div className="product-label-group">
                                                                            <label className="product-label label-new">New</label>
                                                                            <label className="product-label label-sale">-25%</label>
                                                                        </div> */}
                                                                        <div className="product-action-float">
                                                                            <a className="btn-product-icon btn-cart" style={{ cursor: "pointer " }} onClick={() => this.addcart(objectproductnew[1])} title="เพิ่มเข้าตะกร้า">
                                                                                <i className="p-icon-cart-solid"></i>
                                                                            </a>
                                                                            <a href="#" className="btn-product-icon btn-wishlist" title="Add to Wishlist">
                                                                                <i className="p-icon-heart-solid"></i>
                                                                            </a>
                                                                            {/* <a href="#" className="btn-product-icon btn-quickview" title="Quick View">
                                                                                <i className="p-icon-search-solid"></i>
                                                                            </a> */}
                                                                        </div>
                                                                    </figure>
                                                                    <div className="product-details">
                                                                        <h5 className="product-name">
                                                                            <a href={"/product?code=" + objectproductnew[1].ProductCode}>
                                                                                {objectproductnew[1].ProductDesc}
                                                                            </a>
                                                                        </h5>
                                                                        <span className="product-price">
                                                                            {/* <del className="old-price">$42.00</del> */}
                                                                            <ins className="new-price">{objectproductnew[1].ProductPrice} บาท</ins>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="product shadow-media text-center">
                                                                    <figure className="product-media">
                                                                        <a href={"/product?code=" + objectproductnew[2].ProductCode}>
                                                                            <img src={objectproductnew[2].Path !== null ? this.state.configlink + "/" + objectproductnew[2].Path : Productionlink + "/Files/Images/default-image.jpg"} alt="product" width="240" height="300" />
                                                                        </a>
                                                                        <div className="product-action-float">
                                                                            <a className="btn-product-icon btn-cart" style={{ cursor: "pointer " }} onClick={() => this.addcart(objectproductnew[1])} title="เพิ่มเข้าตะกร้า">
                                                                                <i className="p-icon-cart-solid"></i>
                                                                            </a>
                                                                            <a href="#" className="btn-product-icon btn-wishlist" title="Add to Wishlist">
                                                                                <i className="p-icon-heart-solid"></i>
                                                                            </a>
                                                                            {/* <a href="#" className="btn-product-icon btn-quickview" title="Quick View">
                                                                                <i className="p-icon-search-solid"></i>
                                                                            </a> */}
                                                                        </div>
                                                                    </figure>
                                                                    <div className="product-details">
                                                                        <h5 className="product-name">
                                                                            <a href={"/product?code=" + objectproductnew[2].ProductCode} >
                                                                                {objectproductnew[2].ProductDesc}
                                                                            </a>
                                                                        </h5>
                                                                        <span className="product-price">
                                                                            <span className="price">{objectproductnew[2].ProductPrice} บาท</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div> : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        {
                                            objectproduct != null ?
                                                <section>
                                                    <h2 className="mt-10 mb-4 title">สินค้าน่าสนใจ</h2>
                                                    <div className="owl-carousel owl-theme row cols-lg-4 cols-md-3 cols-sm-2" data-owl-options="{
                                      'items': 4,
                                      'margin': 20,
                                      'dots': true,
                                      'nav': false,
                                      'responsive': {
                                          '0': {
                                              'items': 1 
                                          },
                                          '576': {
                                              'items': 2
                                          },
                                          '768': {
                                              'items': 3
                                          },
                                          '992': {
                                              'items': 4
                                          }
                                      }
                                  }">
                                                        <div className="category category-simple overlay-zoom">
                                                            <a href="#">
                                                                <figure>
                                                                    <img src={objectproduct[0].Path !== null ? this.state.configlink + "/" + objectproduct[0].Path : Productionlink + "/Files/Images/default-image.jpg"} alt="category" width="295" height="295" />
                                                                </figure>
                                                            </a>
                                                            <div className="category-content">
                                                                <h5 className="category-name"><a href="#">{objectproduct[0].ProductDesc}</a>
                                                                </h5>

                                                            </div>
                                                        </div>
                                                        <div className="category category-simple overlay-zoom">
                                                            <a href="#">
                                                                <figure>
                                                                    <img src={objectproduct[1].Path !== null ? this.state.configlink + "/" + objectproduct[1].Path : Productionlink + "/Files/Images/default-image.jpg"} alt="category" width="295" height="295" />
                                                                </figure>
                                                            </a>
                                                            <div className="category-content">
                                                                <h5 className="category-name"><a href="#">{objectproduct[1].ProductDesc}</a>
                                                                </h5>

                                                            </div>
                                                        </div>
                                                        <div className="category category-simple overlay-zoom">
                                                            <a href="#">
                                                                <figure>
                                                                    <img src={objectproduct[2].Path !== null ? this.state.configlink + "/" + objectproduct[2].Path : Productionlink + "/Files/Images/default-image.jpg"} alt="category" width="295" height="295" />
                                                                </figure>
                                                            </a>
                                                            <div className="category-content">
                                                                <h5 className="category-name"><a href="#">{objectproduct[2].ProductDesc}</a>
                                                                </h5>

                                                            </div>
                                                        </div>
                                                        <div className="category category-simple overlay-zoom">
                                                            <a href="#">
                                                                <figure>
                                                                    <img src={objectproduct[3].Path !== null ? this.state.configlink + "/" + objectproduct[3].Path : Productionlink + "/Files/Images/default-image.jpg"} alt="category" width="295" height="295" />
                                                                </figure>
                                                            </a>
                                                            <div className="category-content">
                                                                <h5 className="category-name"><a href="#">{objectproduct[3].ProductDesc}</a>
                                                                </h5>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </section> : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="page-content mb-10 shop-page">
                        <div className="container">
                            <div className="row main-content-wrap">
                                <aside
                                    className="col-lg-3 sidebar widget-sidebar sidebar-fixed sidebar-toggle-remain shop-sidebar sticky-sidebar-wrapper">
                                    <div className="sidebar-overlay"></div>
                                    <a className="sidebar-close" href="#"><i className="p-icon-times"></i></a>
                                    <div className="sidebar-content">
                                        <div className="sticky-sidebar pt-7" data-sticky-options="{'top': 10}">
                                            <div className="widget widget-collapsible">
                                                <h3 className="widget-title title-underline"><span className="title-text">ราคา</span></h3>
                                                <div className="widget-body">
                                                    <form action="#" className="pt-2">
                                                        <div className="filter-price-slider"></div>

                                                        <div className="filter-actions">
                                                            <button type="submit" className="btn btn-dim btn-filter">กรอง</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="widget widget-collapsible">
                                                <h3 className="widget-title title-underline"><span
                                                    className="title-text">หมวดหมู่</span></h3>
                                                <ul className="widget-body filter-items">
                                                    <li><a href="#">ทั่วไป</a></li>
                                                    <li><a href="#">โปรโมชั่น</a></li>
                                                </ul>
                                            </div>
                                            {/* <div className="widget widget-collapsible">
                                                <h3 className="widget-title title-underline"><span className="title-text">สินค้าน่าสนใจ</span></h3>
                                                <div className="widget-body">
                                                    <ul className="rating-group filter-items">
                                                        <li className="ratings-container">
                                                            <div className="ratings-full">
                                                                <span className="ratings" style={{ width: "100%" }}></span>
                                                                <span className="tooltiptext tooltip-top"></span>
                                                            </div>
                                                            <a href="#" className="rating-reviews hash-scroll">22</a>
                                                        </li>
                                                        <li className="ratings-container">
                                                            <div className="ratings-full">
                                                                <span className="ratings" style={{ width: "80%" }}></span>
                                                                <span className="tooltiptext tooltip-top"></span>
                                                            </div>
                                                            <a href="#" className="rating-reviews hash-scroll">15</a>
                                                        </li>
                                                        <li className="ratings-container">
                                                            <div className="ratings-full">
                                                                <span className="ratings" style={{ width: "60%" }}></span>
                                                                <span className="tooltiptext tooltip-top"></span>
                                                            </div>
                                                            <a href="#" className="rating-reviews hash-scroll">18</a>
                                                        </li>
                                                        <li className="ratings-container">
                                                            <div className="ratings-full">
                                                                <span className="ratings" style={{ width: "40%" }}></span>
                                                                <span className="tooltiptext tooltip-top"></span>
                                                            </div>
                                                            <a href="#" className="rating-reviews hash-scroll">0</a>
                                                        </li>
                                                        <li className="ratings-container">
                                                            <div className="ratings-full">
                                                                <span className="ratings" style={{ width: "20%" }}></span>
                                                                <span className="tooltiptext tooltip-top"></span>
                                                            </div>
                                                            <a href="#" className="rating-reviews hash-scroll">0</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}
                                            <div className="widget widget-collapsible">
                                                <h3 className="widget-title title-underline"><span className="title-text">สินค้า แท๊ก</span></h3>
                                                <div className="widget-body mt-1">
                                                    <a href="#" className="tag">
                                                        น้ำ
                                                    </a>
                                                    <a href="#" className="tag">
                                                        ยา
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                                <div className="col-lg-9 main-content pl-lg-6">
                                    <nav className="toolbox sticky-toolbox sticky-content fix-top">
                                        <div className="toolbox-left">
                                            <a href="#"
                                                className="toolbox-item left-sidebar-toggle btn btn-outline btn-primary btn-icon-right d-lg-none"><span>Filter</span><i
                                                    className="p-icon-category-1 ml-md-1"></i></a>
                                            <div className="toolbox-item toolbox-sort select-menu" >
                                                <label>เรียงตาม :</label>
                                                <select name="orderby" >
                                                    <option value="default" defaultValue="selected">ปกติ</option>
                                                    <option value="popularity">สินค้าขายดี</option>
                                                    <option value="rating">สินค้าราคาถูก</option>
                                                    <option value="date">สินค้าราคาแพง</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="toolbox-right">
                                            <div className="toolbox-item toolbox-show select-box">
                                                <label>โชว์ :</label>
                                                <select name="count">
                                                    <option value="12">12</option>
                                                    <option value="24">24</option>
                                                    <option value="36">36</option>
                                                </select>
                                            </div>
                                            <div className="toolbox-item toolbox-layout">
                                                <a href="shop-list.html" className="p-icon-list btn-layout active"></a>
                                                <a href="shop-3-cols.html" className="p-icon-grid btn-layout"></a>
                                            </div>
                                        </div>
                                    </nav>
                                    <div className="row product-wrapper cols-lg-3 cols-2">
                                        {this.state.productlist.map(listitem => (
                                            <div className="product-wrap">
                                                <div className="product shadow-media text-center">
                                                    <figure className="product-media">
                                                        <a href={"/product?code=" + listitem.ProductCode}>
                                                            <img src={listitem.Path !== null ? this.state.configlink + "/" + listitem.Path : Productionlink + "/Files/Images/default-image.jpg"} alt="product" width="295"
                                                                height="369" />
                                                            <img src={listitem.Path !== null ? this.state.configlink + "/" + listitem.Path : Productionlink + "/Files/Images/default-image.jpg"} alt="product" width="295"
                                                                height="369" />
                                                        </a>
                                                        <div className="product-action-vertical">
                                                            <a className="btn-product-icon btn-cart" style={{ cursor: "pointer " }} onClick={() => this.addcart(listitem)} title="เพิ่มเข้าตะกร้า">
                                                                <i className="p-icon-cart-solid"></i>
                                                            </a>
                                                            <a href="#" className="btn-product-icon btn-wishlist"
                                                                title="Add to Wishlist">
                                                                <i className="p-icon-heart-solid"></i>
                                                            </a>
                                                            {/* <a href="#" className="btn-product-icon btn-quickview" title="Quick View">
                                                                <i className="p-icon-search-solid"></i>
                                                            </a> */}
                                                        </div>
                                                    </figure>
                                                    <div className="product-details">
                                                        <div className="ratings-container">
                                                            {/* <div className="ratings-full">
                                                                <span className="ratings" style={{ width: "60%" }}></span>
                                                                <span className="tooltiptext tooltip-top"></span>
                                                            </div> */}
                                                            {/* <a href={"/product?code=" + listitem.ProductCode}
                                                                className="rating-reviews">(12)</a> */}
                                                        </div>
                                                        <h5 className="product-name">
                                                            <a href={"/product?code=" + listitem.ProductCode}>
                                                                {listitem.ProductDesc}
                                                            </a>
                                                        </h5>
                                                        <span className="product-price">
                                                            {/* <del className="old-price">{listitem.ProductPrice} บาท </del> */}
                                                            <ins className="new-price">{listitem.ProductPrice} บาท</ins>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* <nav className="toolbox toolbox-pagination pt-2 pb-6">
                                        <p className="toolbox-item show-info">Showing <span>1-6 of 30</span> Products
                                        </p>
                                        <ul className="pagination">
                                            <li className="page-item disabled">
                                                <a className="page-link page-link-prev" href="#" aria-label="Previous" tabIndex="-1"
                                                    aria-disabled="true">
                                                    <i className="p-icon-angle-left"></i>
                                                </a>
                                            </li>
                                            <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a>
                                            </li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item page-item-dots"></li>
                                            <li className="page-item"><a className="page-link" href="#">5</a></li>
                                            <li className="page-item">
                                                <a className="page-link page-link-next" href="#" aria-label="Next">
                                                    <i className="p-icon-angle-right"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav> */}
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            );

        }

    }
}

export default App;
