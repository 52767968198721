import React, { Component, Fragment } from "react";
// import './App.css';
import axios from 'axios';
import Pagination from "react-js-pagination";
import { css } from "@emotion/core";
import jwt_decode from "jwt-decode";
import '../vendor/fontawesome-free/css/all.min.css'
import '../vendor/animate/animate.min.css'
import '../vendor/owl-carousel/owl.carousel.min.css'
import '../vendor//magnific-popup/magnific-popup.min.css'
import '../vendor//magnific-popup/magnific-popup.min.css'
import '../css/demo3.min.css'
import '../css/tree.css'
import instance from "../axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import { OutdoorGrillSharp } from "@material-ui/icons";
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            treelist: [],
            Keycode: null,
            Expire: false,
            selecttype: "normal",
            KeyCode: "",
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false,
            alert: null, // Indicates submit status of login form
        }
        this.hideAlert = this.hideAlert.bind(this);
    }

    componentDidMount() {
        //  this.validateLoginForm();
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        var membercodes = params.get("checkid");
        var KeyCode = membercodes.split('/');
        this.recheckmembercoderef(KeyCode[0]);
    }

    recheckmembercoderef = (KeyCode) => {
        instance.post("/checkkey_expire", {
            KeyCode: KeyCode,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        console.log(200);
                        this.setState({
                            Expire: true,
                            KeyCode: KeyCode
                        });
                    }
                }
                else {
                    console.log(201);
                    // console.log(555555555555);
                }
            });

    }
    CheckThisGoalError(object) {
        const getAlert = () => (
            <SweetAlert
                error
                title={object.title}
                onConfirm={() => this.hideAlert()}  >
                {object.text}
            </SweetAlert>
        );

        this.setState({
            alert: getAlert()
        });
    }
    CheckThisGoalSuccess(object) {
        const getAlert = () => (
            <SweetAlert
                success
                title={object.title}
                onConfirm={() => this.hideAlert()}  >
                {object.text}
            </SweetAlert>
        );

        this.setState({
            alert: getAlert()
        });
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    clieckregister = (e) => {
        e.preventDefault()
        instance.post("/newnoderegister_NewMaster", {
            KeyCode: this.state.KeyCode,
            ListDetail: this.state.formData,
            Selecttype: this.state.selecttype,
        }).then((res) => {
            console.log(res);
            if (res.data.status === 200) {
                var title = 'ทำรายการเรียบร้อย !'
                var text = 'คุณทำการสร้างเครือข่ายเรียบร้อยแล้ว'
                var object = { title, text }
                this.CheckThisGoalSuccess(object);
            }
            else if (res.data.status === 201) {
                var title = 'ไม่สามารถทำรายการได้!'
                var text = 'คุณยังมีจุดเครือข่ายที่ยังไม่ครบ 300 คะแนน ค้างอยู่'
                var object = { title, text }
                this.CheckThisGoalError(object);

            }
            else if (res.data.status === 202) {
                var title = 'ไม่สามารถทำรายการได้!'
                var text = 'รหัสบัตรประชาชนนี้มีการสมัครแล้วโปรดติดต่อแอดมิน'
                var object = { title, text }
                this.CheckThisGoalError(object);
            }
            else if (res.data.status === 204) {
                var title = 'ไม่สามารถทำรายการได้!'
                var text = 'ลิ้งนี้หมดอายุในการใช้งานโปรดติดต่อสายงาน'
                var object = { title, text }
                this.CheckThisGoalError(object);
            }
            else if (res.data.status === 205) {
                var title = 'ไม่สามารถทำรายการได้!'
                var text = 'จุดเครือข่ายนี้มีการสมัครไปแล้วโปรดติดต่อสายงาน'
                var object = { title, text }
                this.CheckThisGoalError(object);
            }
            else {
            }
        });

    }


    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log(value);
        let { formData } = this.state;
        formData[name] = value;
        this.setState({
            formData: formData,
        });
    }

    handleInputChangeselect = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            selecttype: value,
        });
    }
    render() {
        if (this.state.Expire) {
            return (
                <div className="page-content">
                    <div className="container pt-8 pb-10">
                        <div className="login-popup mx-auto pl-6 pr-6 pb-9">
                            <div className="form-box">
                                <div className="tab tab-nav-underline tab-nav-boxed">
                                    <ul className="nav nav-tabs nav-fill align-items-center justify-content-center mb-4">
                                        <li className="nav-item">
                                            <a href="#register-1" className="nav-link lh-1 ls-normal active">สมัครสมาชิก</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active in" id="register-1">
                                            <form action="#">
                                                <div className="form-group">
                                                    <input type="text" name="Name1" onChange={this.handleInputChange} placeholder="ชื่อ-นามสกุล *" required="" />
                                                    <input type="text" name="IdCard" onChange={this.handleInputChange} placeholder="รหัสบัตรประชาชน *" required="" />
                                                    <div className="row">
                                                        <label>จัดการที่อยุ่</label>
                                                        <div className="col-sm-6 mb-4">
                                                            <label>บ้านเลขที่</label>
                                                            <input type="text" name="AddressNo" onChange={this.handleInputChange} placeholder="" required="" />
                                                        </div>
                                                        <div className="col-sm-6 mb-4">
                                                            <label>หมู่</label>
                                                            <input type="text" name="Mo" onChange={this.handleInputChange} placeholder="" required="" />
                                                        </div>
                                                        <div className="col-sm-6 mb-4">
                                                            <label>แขวง</label>
                                                            <input type="text" name="SubDistrict" onChange={this.handleInputChange} placeholder="" required="" />
                                                        </div>
                                                        <div className="col-sm-6 mb-4">
                                                            <label>เขต</label>
                                                            <input type="text" name="District" onChange={this.handleInputChange} placeholder="" required="" />
                                                        </div>
                                                        <div className="col-sm-6 mb-4">
                                                            <label>จังหวัด</label>
                                                            <input type="text" name="Province" onChange={this.handleInputChange} placeholder="" required="" />
                                                        </div>
                                                        <div className="col-sm-6 mb-4">
                                                            <label>รหัสไปรณีย์</label>
                                                            <input type="text" name="ZipCode" onChange={this.handleInputChange} placeholder="" required="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 mb-4">
                                                            <label>ชนิดรหัส *</label>
                                                            <div className="select-box">
                                                                <select name="typemember" onChange={this.handleInputChangeselect} className="form-control">
                                                                    <option value="normal" >รหัสสามัญ</option>
                                                                    <option value="home" >รหัสบ้าน</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input type="email" name="email" onChange={this.handleInputChange} placeholder="อีเมล์ *" required="" />
                                                    <input type="text" name="userName" onChange={this.handleInputChange} placeholder="เบอร์โทรศัพท์ *" required="" />
                                                    <input type="password" name="Password" onChange={this.handleInputChange} placeholder="รหัสผ่าน *" required="" />
                                                </div>
                                                <div className="form-footer mb-5">
                                                    <div className="form-checkbox">
                                                        <input type="checkbox" id="register-agree-1" name="register-agree-1" required="" />
                                                        <label htmlFor="register-agree-1">ยอมรับเงื่อนใขทั้งหมด</label>
                                                    </div>
                                                </div>
                                                <button className="btn btn-dark btn-block" onClick={(e) => this.clieckregister(e)} type="submit">Register</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.alert}
                </div>
            );
        }
        else {
            return (
                <div className="page-content">
                    <div className="container pt-8 pb-10">
                        <div className="login-popup mx-auto pl-6 pr-6 pb-9">
                            <div className="form-box">
                                <div className="tab tab-nav-underline tab-nav-boxed">
                                    <ul className="nav nav-tabs nav-fill align-items-center justify-content-center mb-4">
                                        <li className="nav-item">
                                            <a href="#register-1" className="nav-link lh-1 ls-normal active">Register</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active in" id="register-1">
                                            <form action="#">
                                                <label htmlFor="signin-remember-1" style={{ color: "red" }}>ลิ้งค์สมัครหมดอายุโปรดติดต่อผู้แนะนำไห้สร้างลิ้งใหม่</label>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default App;
