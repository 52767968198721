import React, { Component, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
// import './App.css';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import { css } from "@emotion/core";
import jwt_decode from "jwt-decode";
import '../vendor/fontawesome-free/css/all.min.css'
import '../vendor/animate/animate.min.css'
import '../vendor/owl-carousel/owl.carousel.min.css'
import '../vendor//magnific-popup/magnific-popup.min.css'
import '../vendor//magnific-popup/magnific-popup.min.css'
import '../css/demo3.min.css'
import '../css/tree.css'
import instance from "../axios-instance";
import { v4 as uuid } from 'uuid';
import system from "../objectdata";
var Productionlink =system.REACT_APP_API_LINK 
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            treelist: [],
            selectedValue: null,
            newlinka: "",
            user_id: "",
            membersponsor: "",
            membernamesponsor: "",
            memberplacement: "",
            membernameplacement: "",
            membersponsor: "",
            memberlastref: "",
            alert: null,
        }
        this.hideAlert = this.hideAlert.bind(this);
    }
    handleChange(selectedOption) {
        this.setState({ selectedValue: selectedOption.target.value });
    }

    CheckThisGoalError(object) {
        const getAlert = () => (
            <SweetAlert
                error
                title={object.title}
                onConfirm={() => this.hideAlert()}  >
                {object.text}
            </SweetAlert>
        );

        this.setState({
            alert: getAlert()
        });
    }
    CheckThisGoalSuccess(object) {
        const getAlert = () => (
            <SweetAlert
                success
                title={object.title}
                onConfirm={() => this.hideAlert()}  >
                {object.text}
            </SweetAlert>
        );

        this.setState({
            alert: getAlert()
        });
    }
    hideAlert() {
        this.setState({
            alert: null
        });
        const { userAgent } = navigator
        console.log(userAgent.includes('Line'))
        if (userAgent.includes('Line')) {
            window.location.href = "/accountline";
        }

    }
    clieckselfregister = (e) => {
        e.preventDefault()
        var token = localStorage.getItem('auth_token');
        instance.post("/newnode_register", {
            username: token,
            membersponsor: this.state.membersponsor,
            memberplacement: this.state.memberplacement,
            selectedValue: this.state.selectedValue,
            memberlastref: this.state.memberlastref,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        var title = 'ทำรายการเรียบร้อย !'
                        var text = 'คุณทำการสร้างเครือข่ายเรียบร้อยแล้ว'
                        var object = { title, text }
                        this.CheckThisGoalSuccess(object);
                    }
                }
                else if (res.data.status === 201) {
                    var title = 'ไม่สามารถทำรายการได้!'
                    var text = 'คุณยังมีจุดเครือข่ายที่ยังคะแนนไม่ครบค้างอยู่'
                    var object = { title, text }
                    this.CheckThisGoalError(object);

                }
                else if (res.data.status === 202) {
                    var title = 'ไม่สามารถทำรายการได้!'
                    var text = 'รายการเครือข่ายจุดนี้มีผู้ดำเนินการแล้ว'
                    var object = { title, text }
                    this.CheckThisGoalError(object);
                }
                else if (res.data.status === 203) {
                    var title = 'ไม่สามารถทำรายการได้!'
                    var text = 'รายการเครือข่ายจุดนี้มีผู้ดำเนินการแล้ว'
                    var object = { title, text }
                    this.CheckThisGoalError(object);
                }
                else {
                }
            });

    }

    componentDidMount() {
        this.validateLoginForm();
        var token = localStorage.getItem('auth_token');
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        var membercodes = params.get("genid");
        var memberedit = membercodes.split('/');
        var memberplacement = this.recheckmembercoderef(memberedit[0])
        this.setState({
            memberplacement: memberplacement,
            selectedValue: memberedit[1],
            memberlastref: memberedit[0],
        });

        if (token) {
            // var decoded = jwt_decode(token);
            var Username = token;
            // this.rechecklogin(Username);
            this.setState({
                user_id: token,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }

    }

    validateLoginForm = (e) => {
        var token = localStorage.getItem('auth_token');
        instance.post("/check_user", {
            username: token,
            type: "newgister",
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        //console.log(12565);
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            membersponsor: res.data.resdata.MemberCode,
                            membernamesponsor: res.data.resdata.Name1,
                        });
                        //  window.location.reload();
                    }
                }
                else {
                    // console.log(555555555555);
                }
            });
    }

    recheckmembercoderef = (membercoderef) => {
        instance.post("/checkmember_coderef", {
            MemberCodeRef: membercoderef,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        this.setState({
                            memberplacement: res.data.resdata.MemberCode,
                            membernameplacement: res.data.resdata.Name1,
                        });
                    }
                }
                else {
                    // console.log(555555555555);
                }
            });

    }

    genaratelinkssss(e) {
        e.preventDefault()
        const unique_id = uuid();
        var token = localStorage.getItem('auth_token');
        instance.post("/newnoderegister_newMember", {
            username: token,
            membersponsor: this.state.membersponsor,
            memberplacement: this.state.memberplacement,
            selectedValue: this.state.selectedValue,
            memberlastref: this.state.memberlastref,
            KeyCode: unique_id,
        })
            .then((res) => {
                console.log(res.data.status);
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        var title = 'ทำรายการเรียบร้อย !'
                        var text = 'คุณทำการสร้างเครือข่ายเรียบร้อยแล้ว'
                        var link = Productionlink + "/register?checkid=" + unique_id;
                        this.setState({
                            newlinka: link,
                        });
                        var object = { title, text }
                        this.CheckThisGoalSuccess(object);
                    }
                }
                else if (res.data.status === 201) {
                    var title = 'ไม่สามารถทำรายการได้!'
                    var text = 'คุณยังมีจุดเครือข่ายที่ยังไม่ครบ 300 คะแนน ค้างอยู่'
                    var object = { title, text }
                    this.CheckThisGoalError(object);

                }
                else if (res.data.status === 202) {
                    var title = 'ไม่สามารถทำรายการได้!'
                    var text = 'รายการเครือข่ายจุดนี้มีผู้ดำเนินการแล้ว'
                    var object = { title, text }
                    this.CheckThisGoalError(object);
                }
                else if (res.data.status === 203) {
                    if (res.data.KeyCode != null) {
                        var link = Productionlink + "/register?checkid=" + res.data.KeyCode;
                        this.setState({
                            newlinka: link,
                        });
                        var title = 'ไม่สามารถทำรายการได้!'
                        var text = 'คุณทำรายการสร้างลิ้งนี้อยู่แล้ว สามารถ Copy ด้านล่างได้เลย'
                        var object = { title, text }
                        this.CheckThisGoalError(object);
                    } else {
                        var title = 'ไม่สามารถทำรายการได้!'
                        var text = 'รายการนี้มีผู้สร้างลิ้งสมัครแล้ว'
                        var object = { title, text }
                        this.CheckThisGoalError(object);
                    }
                }
                else if (res.data.status === 204) {
                    var title = 'ไม่สามารถทำรายการได้!'
                    var text = 'มีข้อผิดพลาด'
                    var object = { title, text }
                    this.CheckThisGoalError(object);
                }
            });



    }

    copyToClipboard = (e) => {
        e.preventDefault();
        var text = this.state.newlinka;
        navigator.clipboard.writeText(text);
    };

    render() {
        return (
            <div className="main account-page">
                <nav className="breadcrumb-nav has-border">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li><a href="/main">หน้าหลัก</a></li>
                            <li>สมัครเครือข่าย</li>
                        </ul>
                    </div>
                </nav>
                <div className="page-content">
                    <div className="container ">
                        <div className="login-popup mx-auto pl-12 pr-6 pb-9">
                            <div className="form-box">
                                <div className="tab tab-nav-underline tab-nav-boxed">
                                    <ul className="nav nav-tabs nav-fill align-items-center justify-content-center mb-4">
                                        <li className="nav-item">
                                            <a className="nav-link active lh-1 ls-normal" href="#signin-1">สมัครเอง</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#register-1" className="nav-link lh-1 ls-normal">แนะนำคนอื่น</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="signin-1">
                                            <form action="#">
                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <label>SponsorCode *</label>
                                                        <div className="select-box">
                                                            <select name="Sponsorcode" className="form-control">
                                                                <option value="User" defaultValue>{this.state.membersponsor + " - " + this.state.membernamesponsor}</option>
                                                            </select>
                                                        </div>
                                                        <label>ผู้แนะนำ *</label>
                                                        <div className="select-box">
                                                            <select selectedvalue={this.state.selectedValue} name="Sponsorcode" className="form-control">
                                                                <option value="LR" defaultValue>{this.state.selectedValue != "R" ? "ซ้าย" : "ขวา"} </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <label>ตำแหน่งสายงาน *</label>
                                                        <div className="select-box">
                                                            <select name="PlacementCode" className="form-control">
                                                                <option value="us" defaultValue>{this.state.memberplacement + " - " + this.state.membernameplacement}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span>
                                                    <small className="d-block mb-4">กรุณาใส่ข้อมูลไห้ครบถ้วน</small>
                                                </span>
                                                <button type="submit" className="btn btn-primary" onClick={(e) => this.clieckselfregister(e)}>สมัครเอง</button>
                                            </form>
                                        </div>
                                        <div className="tab-pane" id="register-1">
                                            <form action="#">
                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <label>ผู้แนะนำ *</label>
                                                        <div className="select-box">
                                                            <select name="PlacementCode" className="form-control">
                                                                <option value="User" defaultValue>{this.state.membersponsor + " - " + this.state.membernamesponsor}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <label>ตำแหน่งสายงาน  *</label>
                                                        <div className="select-box">
                                                            <select selectedvalue={this.state.selectedValue} name="PlacementCode" className="form-control">
                                                                <option value="LR" defaultValue>{this.state.selectedValue != "R" ? "ซ้าย" : "ขวา"} </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 mb-4">
                                                        <label>สายงาน *</label>
                                                        <div className="select-box">
                                                            <select name="PlacementCode" className="form-control">
                                                                <option value="Usercode" defaultValue>{this.state.memberplacement + " - " + this.state.membernameplacement}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span>
                                                    <small className="d-block mb-4">กรุณาใส่ข้อมูลไห้ครบถ้วน</small>
                                                </span>
                                                <button type="submit" onClick={(e) => this.genaratelinkssss(e)} className="btn btn-primary">สร้างลิ้ง</button>
                                                <br></br> <br></br>
                                                <input
                                                    id={'todoName'}
                                                    className="form-control"
                                                    style={{ color: "black" }}
                                                    type="text"
                                                    value={this.state.newlinka}
                                                    readOnly
                                                    placeholder="link"
                                                />
                                                <button onClick={(e) => this.copyToClipboard(e)} className="btn btn-dark btn-block" type="submit">Copy text</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.alert}
            </div>
        );
    }
}

export default App;
