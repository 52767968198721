import React, { Component, Fragment } from "react";
import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import instance from "./axios-instance";
import Pagination from "react-js-pagination";
import Login from './login/Loginxx';
import { css } from "@emotion/core";
import jwt_decode from "jwt-decode";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
// import './vendormagnific-popup/magnific-popup.min.css'
// import './vendormagnific-popup/magnific-popup.min.css'
import './css/demo3.min.css'
import './css/tree.css'
import { Redirect } from 'react-router-dom'
import BinTree from './bintree';
import Orderlist from './order/orderlist';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const liff = window.liff;
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            point: 0,
            cash: 0,
            formData: {},
            errors: {},
            formSubmitted: false,
            loading: false,
            profiledetail: null,
            name: '',
            userLineID: '',
            pictureUrl: '',
            statusMessage: '',
            profile: '',
            languageDevice: '',
            versionSDK: '',
            client: '',
            isLogin: '',
            os: '',


        }
    }
    getProfile = async () => {
        var token = localStorage.getItem('auth_token');     
        liff.getProfile().then(async dataInfo => {
            await instance.post("/check_user", {
                username: token,
                type: "registerline",
                userLineID: dataInfo.userId,
            }).then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            userLineID: dataInfo.userId,
                            point: res.data.resdata.WalletPoint,
                            cash: res.data.resdata.WalletCash,
                            profiledetail: res.data.resdata
                        });
                        window.location.href = '/home';
                    }
                    else {
                        localStorage.removeItem('auth_token');
                        window.location.href = '/registerline';
                    }
                }
                else {
                    localStorage.removeItem('auth_token');
                    if(token !== null) window.location.href = '/registerline';
                    console.log(55555555555555555);
                }
            });
            this.setState({
                name: dataInfo.displayName,
                userLineID: dataInfo.userId,
                pictureUrl: dataInfo.pictureUrl,
                statusMessage: dataInfo.statusMessage
            });
            //this.GetName(dataInfo.userId);

        });

        //  const languageDevice = liff.getLanguage();
        //  const versionSDK = liff.getVersion();
        //  const client = liff.isInClient();
        //  const isLogin = liff.isLoggedIn();
        // const os = liff.getOS();

     

    }
    closeLIFF() {
        liff.closeWindow();
    }

    componentDidMount() {
        const { userAgent } = navigator
        console.log(liff.isInClient());
        console.log(userAgent);
        liff.init({ liffId: '1661498943-kgxPrq6y' })
            .then(async () => {
                if (!liff.isLoggedIn()) {
                    liff.login();
                }
               await this.getProfile();
            })
            .catch((err) => {
                console.log(err)
            });

    }


    scancode = async (event) => {
        const result = await liff.scanCodeV2();
        await this.getProfile();
        var token = localStorage.getItem('auth_token');
        liff.getProfile().then(async dataInfo => {
            await instance.post("/confirmregister", {
                username: token,
                userLineID: dataInfo.userId,
                name: dataInfo.displayName,
                pictureUrl: dataInfo.pictureUrl,
                linepasskey: result.value,
                type: "qrcode"
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        if (res.data.resdata !== null) {
                            window.location.href = '/home';
                        }
                    }
                    else if (res.data.status === 201) {

                    }
                });
        });

    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let { formData } = this.state;
        formData[name] = value;
        this.setState({
            formData: formData,
            hiddenfiled: "none",
        });
    }
    onclickssummit = (e) => {

        var token = localStorage.getItem('auth_token');
        instance.post("/confirmregister", {
            username: token,
            userLineID: this.state.userLineID,
            name: this.state.name,
            pictureUrl: this.state.pictureUrl,
            profile: this.state.formData,
            type: "normal"
        })
            .then((res) => {
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        window.location.href = '/home';
                    }
                }
                else if (res.data.status === 201) {

                }
            });
    }

    handleChange = (e) => {

    }
    render() {

        return (
            <div className="">
                <form action="#">
                    <div className="page-content mt-4 mb-10 pb-6">
                        <div className="container">
                            <div className="tab tab-vertical gutter-lg">
                                <ul className="nav nav-tabs mb-8 col-lg-3 col-md-4">
                                    <div className="row">
                                        <h4 >รหัสผูก Line กับระบบ Muyzen</h4>
                                        <div className="col-sm-6 mb-4">
                                            <label>UserName</label>
                                            <input type="text" name="username" onChange={this.handleInputChange} placeholder="" required="" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 mb-4">
                                            <label>Passkey (เอาจากหน้าเว็บ)</label>
                                            <input type="text" name="linePasskey" onChange={this.handleInputChange} placeholder="" required="" />
                                        </div>
                                    </div>
                                </ul>
                                <fieldset>
                                    <label>Confirm password</label>
                                    <input type="password" name="confirm_password" />
                                </fieldset>

                                <div >
                                    <button type="submit" onClick={() => this.scancode()} className="btn btn-info">SCAN QR</button>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <button type="submit" onClick={() => this.onclickssummit()} className="btn btn-primary">ผูกไลน์</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        );
    }
}

export default App;
