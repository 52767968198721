import React, { Component } from 'react';
//import axios from 'axios';
import './Login.css';
import jwt_decode from "jwt-decode";
import SweetAlert from 'react-bootstrap-sweetalert';
import Loading from '../Loading';
import { Redirect } from 'react-router-dom'
import instance from "../axios-instance";
import CartShort from '../cartshort';
import axios from 'axios';

const liff = window.liff;
class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: false, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: null,
            point: 0,
            cash: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            hiddenfiled: "none",
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let { formData } = this.state;
        formData[name] = value;
        this.setState({
            formData: formData,
            hiddenfiled: "none",
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }


    validateLoginForm = async (e) => {
        // console.log("TEST");
        const { formData } = this.state;
        await instance.post("/getuser", {
            username: formData.username,
            password: formData.password,
        })
            .then((res) => {
                console.log(res.data.status);
                if (res.data.status === 200) {
                    console.log(res.data);
                    if (res.data.resdata !== null) {
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            point: res.data.resdata.WalletPoint,
                            cash: res.data.resdata.WalletCash,
                        });
                        this.setState({
                            errors: true
                        });
                    }
                    else {

                        this.setState({
                            errors: false
                        });
                    }
                }
                else {
                    this.setState({
                        errors: false
                    });
                }
            });
    }

    login = async (e) => {
        // e.preventDefault();
        await this.validateLoginForm();
        const { errors } = this.state;
        console.log(errors);
        if (errors === true) {
            window.location.reload();
        } else if (errors === false) {
            this.setState({
                errors: errors,
                formSubmitted: true,
                hiddenfiled: "block",
            });
        }
    }
    validateLogout = (e) => {
        localStorage.removeItem('auth_token', '')
        this.setState({
            user_id: null,
            playerid: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
    }

    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            // alert("You are successfully signed in...");
            window.location.reload()
        } else {

            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.reload()
        }
    }

    nexttoprofile = (e) => {
        return <Redirect to='/account' />
    }

    componentDidMount() {
        var token = localStorage.getItem('auth_token');
        if (token !== null) {
            console.log(liff.isInClient());

            if (liff.isInClient()) {
                localStorage.removeItem('auth_token')
            }
            instance.post("/check_user", {
                type: "login",
                username: token,
            }).then((res) => {
                console.log(res);
                if (res.data.status === 200) {
                    if (res.data.resdata !== null) {
                        localStorage.setItem('auth_token', res.data.token)
                        this.setState({
                            user_id: token,
                            point: res.data.resdata.WalletPoint,
                            cash: res.data.resdata.WalletCash,
                            loginstate: true,
                        });

                        //  window.location.reload();
                    }
                    else{
                        localStorage.removeItem('auth_token')
                    }

                }
                else {
                    localStorage.removeItem('auth_token')
                }
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }

    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam = "agreed") val = event.target.checked;
        this.setState({ [nam]: val });
        //this.handleChange(event);
        //  this.changeButtonColor();

    }


    render() {
        const { loginstate, user_id, point, firstname, lastname, cash, hiddenfiled } = this.state
        if (!loginstate) {
            return (
                <div className="dropdown login-dropdown off-canvas">
                    <a className="login-toggle" href="ajax/login.html" data-toggle="login-modal">
                        <span className="sr-only">เข้าสู่ระบบ</span>
                        <i className="p-icon-user-solid"></i>
                    </a>
                    <div className="canvas-overlay"></div>
                    <a href="#" className="btn-close"></a>
                    <div className="dropdown-box scrollable">
                        <div className="login-popup">
                            <div className="form-box">
                                <div className="tab tab-nav-underline tab-nav-boxed">
                                    <ul className="nav nav-tabs nav-fill mb-4">
                                        <li className="nav-item">
                                            <a className="nav-link active lh-1 ls-normal" href="#signin">เข้าสู่ระบบ</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div style={{ display: hiddenfiled }}>   <label style={{ color: "red" }} >ชื่อผู้ใช้งานหรือพาสเวิด ผิดพลาด</label></div>
                                        <div className="tab-pane active" id="signin">
                                            <div className="form-group">
                                                <input type="text" id="username" name="username"
                                                    placeholder="username or Email Address" required="" onChange={this.handleInputChange} />
                                                <input type="password" id="password" name="password" placeholder="Password"
                                                    required="" onChange={this.handleInputChange} />
                                            </div>
                                            <div className="form-footer">
                                                <a href="#" className="lost-link">ลืมพาสเวิด?</a>
                                            </div>
                                            <button className="btn btn-dark btn-block" onClick={() => this.login()} >เข้าสู่ระบบ</button>
                                        </div>
                                        <div className="tab-pane" id="register">
                                            <div className="form-group">
                                                <input type="text" id="register-user" name="register-user" placeholder="Username" required="" />
                                                <input type="email" id="register-email" name="register-email" placeholder="Your Email Address" required="" />
                                                <input type="password" id="register-password" name="register-password"
                                                    placeholder="Password" required="" />
                                            </div>
                                            <div className="form-footer mb-5">
                                                <div className="form-checkbox">
                                                    <input type="checkbox" id="register-agree" name="register-agree" required="" />
                                                    <label >I
                                                        agree to the
                                                        privacy policy</label>
                                                </div>
                                            </div>
                                            <button className="btn btn-dark btn-block" type="submit">Register</button>

                                            <div className="form-choice text-center">
                                                <label className="ls-m">or Register With</label>
                                                <div className="social-links social-link-active ">
                                                    <a href="#" title="Facebook" className="social-link social-facebook fab fa-facebook-f"></a>
                                                    <a href="#" title="Twitter" className="social-link social-twitter fab fa-twitter"></a>
                                                    <a href="#" title="Linkedin" className="social-link social-linkedin fab fa-linkedin-in"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button title="Close (Esc)" type="button" className="mfp-close"><span>×</span></button>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="row login-zone">
                    <div className="col-lg-12" style={{ "backgroundColor": "#f9f9f9", "verticalAlign": "middle" }} id="loginzone">
                        <div className="player-balance"><div className="player-balance">
                            <i className="p-icon-user-solid"></i> User: {user_id}
                            <div className="account-balance balance-wrappers">
                                <div id="product-balance"><span> Point : {point}</span></div>
                                <div id="product-balance"><span> Cash : {cash}</span></div>
                            </div>
                        </div>
                            <div className="header-right">
                                <a className="wishlist wishlist-toggle" style={{ cursor: "pointer " }} href="/account" >
                                    <i className="p-icon-user-solid"></i>
                                </a>
                                <a href="wishlist.html" className="wishlist wishlist-toggle" title="Wishlist">
                                    <i className="p-icon-heart-solid"></i>
                                </a>
                                <CartShort />
                            </div>
                        </div>

                    </div>
                    {this.state.alert}
                </div>

            );
        }
    }
}

export default Login;
