import React, { Component } from 'react';
import logo from './logo.svg';
// import './App.css';
import axios from 'axios';
import Pagination from "react-js-pagination";
import Login from './login/Loginxx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { css } from "@emotion/core";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import MyLoader from './Loading'
import jwt_decode from "jwt-decode";
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
//

class App extends Component {

    render() {
        return (
            <footer className="footer">
            <div className="container">
              <div className="footer-middle">
                <div className="row">
                  <div className="col-lg-4 col-sm-6 mb-6 mb-lg-0">
                    <div className="widget widget-about">
                      <a href="/" className="logo-footer mb-5">
                        <img src="images/demos/demo3/logo.png" alt="logo" width="171" height="41" />
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 mb-6 mb-lg-0">
                    <div className="widget">
                      <h4 className="widget-title">About us</h4>
                      <ul className="widget-body">
                        <li><a href="about.html">ติดต่อเรา</a></li>
                        <li><a href="#">รายการสั่งซื้อ</a></li>
                        <li><a href="#">คืนของ</a></li>
                        <li><a href="#">ลูกค้าสัมพันธ์</a></li>
                        <li><a href="#">เงื่อนใข</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 mb-6 mb-lg-0">
                    <div className="widget widget-account">
                      <h4 className="widget-title">My Account</h4>
                      <ul className="widget-body">
                        <li><a href="#">เข้าสู่ระบบ</a></li>
                        <li><a href="#">ดูตะกร้า</a></li>
                        <li><a href="#">สินค้าที่สนใจ</a></li>
                        <li><a href="#">ติดตามออเดอร์</a></li>
                        <li><a href="#">ช่วยเหลือ</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 mb-6 mb-lg-0">
                    <div className="widget pl-xl-8">
                      <h4 className="widget-title">Contact info</h4>
                      <ul className="widget-body footer-icon-boxes">
                        <li>
                          <a href="tel:#" className="footer-icon-box">
                            <i className="p-icon-phone-solid"></i>
                            <span>036 389 911</span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <i className="p-icon-map"></i>
                            <span>36 M 8, ตำบล ห้วยขมิ้น อำเภอหนองแค สระบุรี 18230</span>
                          </a>
                        </li>
                        <li>
                          <a href="mailto:mail@panda.com" className="">
                            <i className="p-icon-message"></i>
                            <span>info@muyzen.net</span>
                          </a>
                        </li>
                        <li>
                          <a href="#" className="">
                            <i className="p-icon-clock"></i>
                            <span> จันทร์-ศุกร์: 10:00 - 18:00</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bottom">
                <div className="footer-left">
                  <p className="copyright">Muyzen © 2022. All Rights Reserved</p>
                </div>
                <div className="footer-right">
                  <div className="social-links inline-links">
                    <a href="#" title="facebook" className="social-link social-facebook fab fa-facebook-f pt-0"><span
                        className="sr-only">facebook</span></a>
                    <a href="#" title="twitter" className="social-link social-twitter fab fa-twitter pt-0"><span
                        className="sr-only">twitter</span></a>
                    <a href="#" title="pinterest" className="social-link social-pinterest fab fa-pinterest pt-0"><span
                        className="sr-only">pinterest</span></a>
                    <a href="#" title="linkedin" className="social-link social-linkedin fab fa-linkedin-in pt-0 mr-0"><span
                        className="sr-only">linkedin</span></a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        );
    }
}

export default App;
