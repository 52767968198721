
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Login from './login/Loginxx';
import Account from './account';
import Accountline from './accountline';
import App from './App';
import Itemlist from './itemlist';
import Register from './register/register';
import Newregister from './newnode/newregister';
import Product from './productdetail';
import Cartfull from './cartfull';
import Checkout from './chekout';
import Orderfinish from './ordersucess';
import Footer from './footer';
import Updatepoint from './bonusmanage';
import Bintreezoomout from './bintreezoomout';
import Bintreezoomoutweb from './bintreezoomoutweb';
import Bintree from './bintreemobile';
import Mainmobile from './Mobilemenu';
import OrderSuccess from './ordersucesslast';
import RegisterLine from './registerline';


import './css/tree.css'
import './css/style.min.css'
import './css/demo3.min.css';
// ReactDOM.render(
//   <Router>
//   </Router>,
//   document.getElementById('submenu')
// );

ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
  <Router>
    <Route exact path='/' component={Itemlist}></Route>
    <Route exact path='/home' component={Itemlist}></Route>
    <Route exact path='/main' component={Itemlist}></Route>
    <Route exact path='/account' component={Account}></Route>
    <Route exact path='/accountline' component={Accountline}></Route>
    <Route exact path='/register*' component={Register}></Route>    
    <Route exact path='/newregister' component={Newregister}></Route>        
    <Route exact path='/product' component={Product}></Route>   
    <Route exact path='/cart' component={Cartfull}></Route>   
    <Route exact path='/checkout' component={Checkout}></Route>   
    <Route exact path='/OrderSuccess' component={OrderSuccess}></Route>       
    <Route exact path='/orderfinish' component={Orderfinish}></Route>   
   <Route exact path='/updatepoint' component={Updatepoint}></Route>   
   <Route exact path='/Bintreezoomoutweb' component={Bintreezoomoutweb}></Route>   
   <Route exact path='/Bintreezoomout' component={Bintreezoomout}></Route>   
   <Route exact path='/Bintree' component={Bintree}></Route>   
   <Route exact path='/RegisterLine' component={RegisterLine}></Route>   
  </Router>,
  document.getElementById('main')
);

ReactDOM.render(
  <Router>
    <Mainmobile />
  </Router>,
  document.getElementById('mainmobile')
);



ReactDOM.render(
  <Router>
    <Login />
  </Router>,
  document.getElementById('frmlogin')
);


ReactDOM.render(
  <Router>
    <Footer />
  </Router>,
  document.getElementById('footer')
);


// ReactDOM.render(<Footer />, document.getElementById('footer'));
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
