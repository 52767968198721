import React, { Component } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { Redirect } from 'react-router-dom'
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/animate/animate.min.css'
import './vendor/owl-carousel/owl.carousel.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './vendor//magnific-popup/magnific-popup.min.css'
import './css/demo3.min.css'
import system from "./objectdata";
var Productionlink = system.REACT_APP_API_LINK
const styles = theme => ({

    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    mleft: {
        marginLeft: "300px",

    }
});


class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            categorylist: [],
            rewardlist: [],
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: null,
            point: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            modalOpen: false,
            handleClose: false,
            token: window.localStorage.getItem("token"),
            type: "",
            username: "",
            amount: "",
            remark: "",
            name: "",
            left: false,
            errors: {
                username: '',
                name: '',
            },
            touched: {
                username: false,
                name: false,
            },
        }
        this.openModalTxn = this.openModalTxn.bind(this);
        this.closeFunction = this.closeFunction.bind(this);
        this.login = this.login.bind(this);
    }



    login = (e) => {
        e.preventDefault();
        let errors = this.validateLoginForm();
    }

    register = (e) => {
        // e.preventDefault();
        return <Redirect to='/Profile' />
    }

    onChange = event => {
        let nam;
        let val;
        if (event.target.name == undefined) {
            nam = event.name;
            val = event.value;
        } else {
            nam = event.target.name;
            val = event.target.value;
        }
        this.setState({ [nam]: val });

    };

    openModalTxn(valueIntoModal) {
        let vm = valueIntoModal;
        this.setState({
            valueintomodal: vm,
            modalOpen: true,
        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let { formData } = this.state;
        formData[name] = value;

        this.setState({
            formData: formData
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }



    closeFunction() {
        this.setState({
            modalOpen: false,
        });
    }


    validateLoginForm = async (e) => {
        const { username, name } = this.state;
        await axios.post("https://dafarewards.com:7001/api/v1/userlogin", {
            User_id: username,
            name: name,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        user_id: res.data.message.playerid,
                        playerid: res.data.message.member_id,
                        firstname: res.data.message.firstname,
                        lastname: res.data.message.lastname,
                        point: res.data.message.Point,
                        loginstate: true,
                    });
                    window.location.reload(false);
                }
                else {
                    this.setState({
                        user_id: null,
                        playerid: null,
                        firstname: null,
                        lastname: null,
                        point: null,
                        loginstate: false,
                    });
                    this.CheckThisGoalError();
                }
            });
    }

    validateLogout = (e) => {
        localStorage.removeItem('auth_token', '')
        this.setState({
            user_id: null,
            playerid: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
    }

    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            // alert("You are successfully signed in...");
            window.location.reload()
        } else {

            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.reload()
        }
    }

    async componentDidMount() {
        
        console.log(Productionlink);
        // var token = localStorage.getItem('auth_token');
        // if (token) {
        //     var decoded = jwt_decode(token);
        //     var Username = decoded.message.playerid;
        //     this.rechecklogin(Username, token);
        //     this.setState({
        //         user_id: decoded.message.playerid,
        //         playerid: decoded.message.member_id,
        //         firstname: decoded.message.firstname,
        //         lastname: decoded.message.lastname,
        //         loginstate: true,
        //     });
        // }
        // else {
        //     this.setState({
        //         loginstate: false,
        //     });
        // }
    }
    openDrawer = () => {
        this.setState({
            left: true
        });
    };
    toggleDrawer = () => {
        this.setState({ left: false });
    };
    render() {
        const { loginstate, user_id, point, firstname, lastname } = this.state
        const { classNamees } = this.props
        return (<div>
            <React.Fragment>
                <header className="header">
                    <div className="header-top">
                        <div className="container">
                            <div className="header-left">
                                <a href="tel:#" className="call">
                                    <i className="p-icon-phone-solid"></i>
                                    <span>036 389 911</span>
                                </a>
                                <span className="divider"></span>
                                <a href="contact.html" className="contact">
                                    <i className="p-icon-map"></i>
                                    <span>36 m 8, ตำบล ห้วยขมิ้น อำเภอหนองแค สระบุรี 18230</span>
                                </a>
                            </div>
                            <div className="header-right">
                                <div className="dropdown switcher">
                                    <a href="#currency"></a>
                                </div>
                                <div className="social-links">
                                    <a href="#" className="social-link fab fa-facebook-f" title="Facebook"></a>
                                    <a href="#" className="social-link fab fa-twitter" title="Twitter"></a>
                                    <a href="#" className="social-link fab fa-pinterest" title="Pinterest"></a>
                                    <a href="#" className="social-link fab fa-linkedin-in" title="Linkedin"></a>
                                </div>
                            </div>
                        </div>   </div>
                    <div className="header-middle has-center">
                        <div className="container">
                            <div className="header-left">
                                <a href="#" className="mobile-menu-toggle">
                                    <i className="p-icon-bars-solid"></i>
                                </a>
                                <a href="/home" className="logo">
                                    <img src="images/demos/demo3/logo.png" alt="logo" width="171" height="41" />
                                </a>
                            </div>
                            <div className="header-center d-lg-show">
                                <div className="header-search hs-expanded">
                                    <form action="#" className="form-simple">
                                        <input type="text" name="search" autoComplete="off" placeholder="ค้นหา สินค้า..." required />
                                        <button className="btn btn-search" type="submit" title="submit-button">
                                            <i className="p-icon-search-solid"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div className="header-right">
                                <div id="frmlogin" className="frmlogin"></div>

                     
                            </div>
                        </div>
                    </div>

                </header>
            </React.Fragment>
            {this.state.alert}
        </div >
        );
    }
}

export default withStyles(styles)(App);;
